import React from "react";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LabelBox from "./LabelBox/LabelBox";
import CountBox from "./CountBox/CountBox";
import {Checkbox, /*Switch,*/ Tooltip, withStyles} from "@material-ui/core";

export default function FloorEquipmentComponent({floor, updateFloor, data, opened}){

    const [open, setOpen] = React.useState(opened !== undefined ? opened : true);
    function onOpenClose(){
        setOpen(!open);
    }
    function updateRoom(room){
        updateFloor({...floor, rooms: floor.rooms.map(r => r.id !== room.id ? r : room)});
    }

    function generateRoomList(){
        const optionCounter = (data.type === "boolean-single-additional") ? data.options.length + 1 : data.options.length;
        const colSpan = "col-" + (6 / optionCounter);
        return floor.rooms.map(r => generateRoom(r, colSpan.replace('.', '')));
    }
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--color2)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '250px',
            minWidth: '150px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    }))(Tooltip);
    function generateFloorControl(){
        const optionCounter = (data.type === "boolean-single-additional") ? data.options.length + 1 : data.options.length;
        const colSpan = "col-" + (6 / optionCounter);
        switch (data.type) {
            case "count":
                return data.options.map(option => <div className={"text-center " + colSpan.replace('.', '')} key={floor.id + "-" + option.id}><CountBox minValue={0} maxValue={(option.maxCount !== undefined) ? option.maxCount : 99} value={valueForFloorCounter(option)} setValue={value => setValueForSelectedEquipmentFloor(option.id, value)}/></div>);
            case "boolean":
                return data.options.map(option => <div className={"text-center " + colSpan.replace('.', '')} key={floor.id + "-" + option.id}>
                    {data.subtype !== undefined && data.subtype === 'checkbox'
                        ? <Checkbox checked={isChecked(option)}
                                  onChange={e => setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, [], [])}/>
                        : <Checkbox checked={isChecked(option)}
                                  onChange={e => setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, [], [])}/>
                    }
                </div>)
            case "boolean-single":
                return data.options.map(option => <div className={"text-center " + colSpan.replace('.', '')} key={floor.id + "-" + option.id}><Checkbox checked={isChecked(option)} onChange={e => setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, data.options, [])}/></div>);
            case "boolean-single-additional":
                let additionalSelect = null;
                if(data.type === "boolean-single-additional"){
                    const disabled = isOnePerRoomChecked(data.options);
                    additionalSelect = <div className={"text-center " + colSpan.replace('.', '')} key={floor.id + "-" + data.additionalOption.id}><Checkbox disabled={isOnePerRoomChecked(data.options)} checked={isChecked(data.additionalOption)} onChange={e => setValueForSelectedEquipmentFloor(data.additionalOption.id, e.target.checked ? 1 : 0, [], data.options)}/></div>;
                    if(disabled && data.additionalOption.hoverText !== undefined){
                        additionalSelect = <HtmlTooltip title={data.additionalOption.hoverText}>{additionalSelect}</HtmlTooltip>
                    }
                }
                return <>
                    {data.options.map(option => <div className={"text-center " + colSpan.replace('.', '')} key={floor.id + "-" + option.id}><Checkbox checked={isChecked(option)} onChange={e => setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, data.options, [data.additionalOption])}/></div>)}
                    {additionalSelect}
                </>;
            default:
                return null;
        }
    }
    function isChecked(option){
        var checked = true;
        floor.rooms.forEach(r => checked = (checked && Boolean(r.selectedEquipment[option.id])));
        return checked;
    }
    function isOnePerRoomChecked(options){
        var checked = 0;
        floor.rooms.forEach(r => {
            options.forEach(
                option => {if(Boolean(r.selectedEquipment[option.id])){checked++}}
            );
        });
        return (floor.rooms.length !== checked);
    }
    function valueForFloorCounter(option){
        const unique = [...new Set(floor.rooms.map(item => item.selectedEquipment[option.id]))];
        return unique.length > 1 ? 0 : (unique[0] !== undefined ? unique[0] : 0);
    }

    function generateRoomControl(data, room, colSpan){
        switch (data.type) {
            case "count":
                return data.options.map(option => generateRoomCounter(room, colSpan, option));
            case "boolean":
                return data.options.map(option => generateRoomSwitch(room, colSpan, option, [], [], false));
            case "boolean-single":
                return data.options.map(option => generateRoomSwitch(room, colSpan, option, data.options, [], false));
            case "boolean-single-additional":
                return <>
                        {data.options.map(option => generateRoomSwitch(room, colSpan, option, data.options, [data.additionalOption], false))}
                        {generateRoomSwitch(room, colSpan, data.additionalOption, [], data.options, true)}
                    </>;
            default:
                break;
        }
    }

    function generateRoom(room, colSpan){
        return <div className="roomRow row" key={data.id + "-" + room.id}>
            <div className="col-3 contextBoxHover" style={{minWidth: "30%"}}>
                <LabelBox value={room.name} editAble={false} isBold={false}/>
            </div>
            <div className={"col-1"}></div>
            {generateRoomControl(data, room, colSpan)}
        </div>;
    }

    function generateRoomCounter(room, colSpan, option){
        const value = room.selectedEquipment[option.id];
        const maxCount = (option.maxCount !== undefined) ? option.maxCount : 99;
        return <div className={"text-center " + colSpan} key={room.id + "-" + option.id}><CountBox minValue={0} maxValue={maxCount} value={value ? value : 0} setValue={value => setValueForSelectedEquipment(room, option.id, value)}/></div>
    }
    function generateRoomSwitch(room, colSpan, option, deselectOptions, deselectAdditionalOption, isAdditional){
        var disabled = false;
        if(isAdditional){
            disabled = true;
            deselectAdditionalOption.forEach(o => {if(room.selectedEquipment[o.id] === 1){disabled = false;}})

        }
        let content;
        if (data.subtype !== undefined && data.subtype === 'checkbox') {
            content = <Checkbox disabled={disabled} checked={Boolean(room.selectedEquipment[option.id])}
                                onChange={e => setValueForSelectedEquipmentBoolean(room, option.id, e.target.checked ? 1 : 0, deselectOptions, deselectAdditionalOption)}/>;
        } else {
            content = <Checkbox disabled={disabled} checked={Boolean(room.selectedEquipment[option.id])}
                                onChange={e => setValueForSelectedEquipmentBoolean(room, option.id, e.target.checked ? 1 : 0, deselectOptions, deselectAdditionalOption)}/>;
        }
        if(disabled && option.hoverText !== undefined){
            content = <HtmlTooltip title={option.hoverText}>{content}</HtmlTooltip>
        }
        return <div className={"text-center " + colSpan} key={room.id + "-" + option.id}>{content}</div>
    }

    function setValueForSelectedEquipment(room, optionId, value){
        room.selectedEquipment[optionId] = value;
        updateRoom(room);
    }
    function setValueForSelectedEquipmentBoolean(room, optionId, value, deselectOptions, deselectAdditionalOption){
        if(value === 1){
            deselectOptions.forEach(option => room.selectedEquipment[option.id] = 0);
        }else{
            deselectAdditionalOption.forEach(option => room.selectedEquipment[option.id] = 0);
        }
        room.selectedEquipment[optionId] = value;
        updateRoom(room);
    }
    function setValueForSelectedEquipmentFloor(optionId, value){
        floor.rooms.forEach(r => r.selectedEquipment[optionId] = value);
        updateFloor({...floor});
    }
    function setValueForSelectedEquipmentBooleanFloor(optionId, value, deselectOptions, deselectAdditionalOption){
        if(value === 1){
            floor.rooms.forEach(r => {deselectOptions.forEach(option => r.selectedEquipment[option.id] = 0)});
        }else{
            floor.rooms.forEach(r => {deselectAdditionalOption.forEach(option => r.selectedEquipment[option.id] = 0)});
        }
        floor.rooms.forEach(r => r.selectedEquipment[optionId] = value);
        updateFloor({...floor});
    }

    return <div className="floorWrapper" key={data.id + "xx" + floor.id} style={{marginBottom: "70px"}}>
        <div className="floorBoxHeader row">
            <div className={"col-3"}>
                <div className="openClose" onClick={onOpenClose}>
                    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                </div>
                <LabelBox value={floor.name} editAble={false} isBold={true}/>
            </div>
            <div className={"col-1"} style={{textAlign: "center", padding: "10px 0"}}>Alle</div>
            {generateFloorControl()}
        </div>
        {open ? generateRoomList() : null }
    </div>
}



export default function getProducts(room, austattung_produkt, selectedAlternatives){

    const productList = [];

    let countVentil = room.heatingCount + room.floorheatingCount;

    if(
        (room.selectedEquipment["heizen_heizkoerper"] !== undefined
        && Boolean(room.selectedEquipment["heizen_heizkoerper"])
        && austattung_produkt["rt-regler"] !== undefined)
        ||
        (room.selectedEquipment["heizen_fussbodenheizung"] !== undefined
            && Boolean(room.selectedEquipment["heizen_fussbodenheizung"]))
    ){
        productList.push({
            product: austattung_produkt["rt-regler"],
            count: 1
        });
        productList.push({
            product: austattung_produkt["rt-regler-rahmen"],
            count: 1
        });
        // productList.push({
        //     product: austattung_produkt["r-1f"],
        //     count: 1
        // });
    }

    if(
        countVentil > 0
        && austattung_produkt["rt-ventil-1"] !== undefined
    ){
        const altList = [
            {
                key: "rtVentil1",
                value: undefined,
                products: [austattung_produkt["rt-ventil-1"]]
            },
            {
                key: "rtVentil1",
                value: "rtVentil2",
                products: [austattung_produkt["rt-ventil-2"]]
            }
        ];
        if(
            selectedAlternatives !== undefined
            && selectedAlternatives["rtVentil1"] !== undefined
        ) {
            productList.push({
                product: austattung_produkt["rt-ventil-2"],
                count: 1,
                alt: altList
            });
        }else{
            productList.push({
                product: austattung_produkt["rt-ventil-1"],
                count: 1,
                alt: altList
            });
        }
    } else if (countVentil > 0 && austattung_produkt["rt-ventil-2"] !== undefined) {
        productList.push({
            product: austattung_produkt["rt-ventil-2"],
            count: countVentil,
        });
    }

    return productList;
}

import React from "react";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FloorEquipmentComponent from "./FloorEquipmentComponent";
import CountBox from "./CountBox/CountBox";
import {Checkbox, /*Switch,*/ Tooltip, withStyles} from "@material-ui/core";

export default function EquimentSelectComponent({project, equipmentData, updateProject, opened, idx, setLevel, saveIdTracking}){

    if(project.selectedEquipment === undefined){
        project.selectedEquipment = [];
    }

    const [open, setOpen] = React.useState(opened === undefined ? equipmentData.selectableFor === "project" || idx === 0 : opened);
    function onOpenClose(){
        if (equipmentData.selectableFor === "project") { return false; }
        switch (equipmentData.id) {
            case 'schaltbare_beleuchtung_steckdosen': saveIdTracking('btn_accordion', 'btn_accordion_light');break;
            case 'verschattung': saveIdTracking('btn_accordion', 'btn_accordion_shading');break;
            case 'heizung': saveIdTracking('btn_accordion', 'btn_accordion_heating');break;
            case 'steckdosen_ladedosen': saveIdTracking('btn_accordion', 'btn_accordion_plug');break;
            case 'anschlussdosen': saveIdTracking('btn_accordion', 'btn_accordion_connector');break;
            case 'sicherheit': saveIdTracking('btn_accordion', 'btn_accordion_security');break;
            default: break;
        }
        setOpen(!open);
    }


    function generateTableHead(withFullCount){
        const optionCount = (equipmentData.type === "boolean-single-additional") ?  equipmentData.options.length + 1 : equipmentData.options.length;
        const colSpan = "col-" + (6 / optionCount);
        return  <div className="tableHeader row">
                    <div className={"" + (equipmentData.selectableFor === "project" ? "col-4" : "col-4") }>
                        <div id={equipmentData.titleId ? equipmentData.titleId : ''} className={"equimentBoxHeader" + (equipmentData.titleClassname ? (" " + equipmentData.titleClassname) : "")} onClick={onOpenClose}>
                            <div className="openClose">
                                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                            </div>
                            <span className={"equimentBoxHeader-title"}>{equipmentData.title}</span>
                            {/*<div>{returnStars()}</div>*/}
                        </div>
                        <div className={"equimentBoxHeader-text"}>{equipmentData.subtitle.split('\n').map(function (item, key) {return (<span key={key}>{item}<br/></span>)})}</div>
                        {equipmentData.text !== undefined ? (<div className={"equimentBoxHeader-text bottomLeft"}>{equipmentData.text}</div>) : null}
                    </div>
                    { equipmentData.options.map( option =>{
                        let content = <div className={"text-center " + colSpan.replace('.', '')} key={equipmentData.id + "-" + option.id} onClick={onOpenClose}>
                            { withFullCount ? <div className="optionCounter" style={{cursor: "pointer"}}>{getFullCount(option, equipmentData.type, true)}</div> : null }
                            <img src={process.env.PUBLIC_URL + "/images/austattungen/" + option.icon}
                                 alt={""} style={{maxWidth: "100%", maxHeight: "100%", height: "30px", cursor: "pointer"}}
                                 onMouseOver={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.iconOver : null)}
                                 onMouseOut={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.icon : null)}
                            />
                            <div className="svgDescription-step-two" style={{cursor: "pointer"}}>{option.name}</div>
                        </div>;
                        if(option.iconToolTip){
                            return <HtmlTooltip title={option.iconToolTip} key={equipmentData.id + "-" + option.id}>{content}</HtmlTooltip>
                        }
                        return content;
                    })}
                    {
                        (equipmentData.type === "boolean-single-additional") ? <div className={"text-center " + colSpan.replace('.', '')} key={equipmentData.id + "-" + equipmentData.additionalOption.id}>
                            { withFullCount ? <div className="optionCounter">{getFullCount(equipmentData.additionalOption, equipmentData.type)}</div> : null }
                            <img src={process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.icon}
                                 alt={equipmentData.additionalOption.iconTitle ? equipmentData.additionalOption.iconTitle : ""} style={{maxWidth: "100%", maxHeight: "100%", height: "30px"}}
                                 onMouseOver={e => (equipmentData.additionalOption.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.iconOver : null)}
                                 onMouseOut={e => (equipmentData.additionalOption.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.icon : null)}
                            />
                            <div className="svgDescription-step-two">{equipmentData.additionalOption.name}</div>
                        </div> : null
                    }
                </div>;
    }

    function getFullCount(option, type, justCount = false){
        var count = 0;
        var fullCount = 0;
        switch (type) {
            case "boolean":
                project.floors.forEach(
                    floor => floor.rooms.forEach(
                        room => {
                            if(room.selectedEquipment[option.id]){ count++};
                            fullCount++;
                        }
                    )
                );
                return justCount ? <>{count}</> : <>{count}/{fullCount}</>;
            case "count":
            default:
                project.floors.forEach(
                    floor => floor.rooms.forEach(
                        room => {
                            if(room.selectedEquipment[option.id]){count += room.selectedEquipment[option.id];};
                            fullCount++;
                        }
                    )
                );
                return <>{count}</>;
        }
    }

    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : floor)});
    }

    function generateTables(){
        return project.floors.map((floor, idx) =>
            <FloorEquipmentComponent floor={floor} data={equipmentData} updateFloor={updateFloor} key={equipmentData.id + "-" + floor.id} opened={idx === 0}/>
        );
    }

    function generateEquipmentSelectComponentInnerBox(){
        if(open){
            if(equipmentData.selectableFor === "project"){
                var colSpan = "col-" + (6 / equipmentData.options.length);
                var controlls = null;
                switch (equipmentData.type) {
                    case "count":
                        controlls = equipmentData.options.map(option => generateProjectCounter(colSpan, option));
                        break;
                    case "boolean":
                        console.log('equipmentdata', equipmentData)
                        controlls = equipmentData.options.map(option => generateProjectSwitch(colSpan, option, [], [], false));
                        break;
                    case "boolean-single":
                        controlls = equipmentData.options.map(option => generateProjectSwitch(colSpan, option, equipmentData.options, [], false));
                        break;
                    case "boolean-single-additional":
                        colSpan = "col-" + (6 / (equipmentData.options.length + 1));
                        controlls = <>
                            {equipmentData.options.map(option => generateProjectSwitch(colSpan, option, equipmentData.options, [equipmentData.additionalOption], false))}
                            {generateProjectSwitch(colSpan, equipmentData.additionalOption, [], equipmentData.options, true)}
                            </>;
                        break;
                    default:
                        break;
                }
                return <>
                    <div className="projectBoxHeader row">
                    <div className={"col-3"}>
                    </div>
                    <div className={"col-1"} style={{textAlign: "center", padding: "10px 0"}}></div>
                    {controlls}
                </div>
                </>
            }else{
                return <>
                    {generateTables()}
                </>
            }
        }
        return null;
    }

    function generateProjectCounter(colSpan, option){
        const value = project.selectedEquipment[option.id];
        const maxCount = (option.maxCount !== undefined) ? option.maxCount : 99;
        return <div className={"text-center " + colSpan} key={option.id}><CountBox minValue={0} maxValue={maxCount} value={value ? value : 0} setValue={value => setValueForSelectedEquipment(option.id, value)}/></div>
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--color2)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '250px',
            minWidth: '150px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    }))(Tooltip);

    function generateProjectSwitch(colSpan, option, deselectOptions, deselectAdditionalOption, isAdditional){
        var disabled = false;
        if(isAdditional){
            disabled = true;
            deselectAdditionalOption.forEach(o => {if(project.selectedEquipment[o.id] === 1){disabled = false;}})
        }

        var content = <div className={"text-center " + colSpan} key={option.id}><Checkbox disabled={disabled} checked={Boolean(project.selectedEquipment[option.id])} onChange={e => setValueForSelectedEquipmentBoolean(option.id, e.target.checked ? 1 : 0, deselectOptions, deselectAdditionalOption)}/></div>;

        if(disabled && option.hoverText !== undefined){
            content = <HtmlTooltip title={option.hoverText}>{content}</HtmlTooltip>
        }

        return content
    }

    function setValueForSelectedEquipment(optionId, value){
        console.log('setvaluefprselectedequipment', optionId, value, project.selectedEquipment)
        project.selectedEquipment[optionId] = value;
        updateProject(project);
    }
    function setValueForSelectedEquipmentBoolean(optionId, value, deselectOptions, deselectAdditionalOption){
        console.log('value for selected equipment', optionId, value, deselectOptions, deselectAdditionalOption, project.selectedEquipment)
        if(value === 1){
            deselectOptions.forEach(option => project.selectedEquipment[option.id] = 0);
        }else{
            deselectAdditionalOption.forEach(option => project.selectedEquipment[option.id] = 0);
        }
        project.selectedEquipment[optionId] = value;
        updateProject({...project});
    }

    return <>
    <div className={"section-container" + (equipmentData.selectableFor === "project" ? " closed" : "")}>
        <div className={"section-title tableStickyHeader"}>
            <div className={"" + (equipmentData.selectableFor === "project" ? " noposition" : "") + (!open ? " closed" : "")}>
                {generateTableHead(equipmentData.selectableFor === "project" ? false : true)}
            </div>
        </div>
        { generateEquipmentSelectComponentInnerBox() }
    </div>
    </>;
}

import React from "react";
import {Tooltip, Tab, Tabs, withStyles} from "@material-ui/core";
import FloorDesignComponent from "./FloorDesignComponent";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {GenerateImagePath} from "../services/GenerateImagePath";
import Button from "@material-ui/core/Button";
import arrowDown from "../images/icons/icon_arrow_down.svg";
import colEdit from "../images/figma/col_edit.svg";
import colDelete from "../images/figma/col_delete.svg";
import colDeleteGray from "../images/figma/col_delete_gray.svg";
import iconCancel from "../images/icons/Icon_faq-plus.svg"

export default function SelectDesignComponent({schalterdesigns, project, updateProject, uiState, updateUiState, saveIdTracking}){

    const handleChangeSerie = (event, newValue) => {
        let index1 = schalterdesigns[newValue].colorsTaster.findIndex(d => d.isDefault);
        index1 = index1 > 0 ? index1 : 0;
        let index2 = schalterdesigns[newValue].colorsTaster[index1].colorsRahmen.findIndex(d => d.isDefault);
        index2 = index2 > 0 ? index2 : 0;
        saveIdTracking('btn_switch_style', 'btn_switch_style_type_' + schalterdesigns[newValue].name);

        updateUiState({
            ...uiState,
            selectedSerie: newValue,
            selectedColorTaster: index1 ,
            selectedColorRahmen: index2,
            designChanged: uiState.selectedDesignList.length < 3 ? true : uiState.designChanged
        });
    };

    const handleChangeColor1 = (event, newValue) => {
        let index2 = schalterdesigns[uiState.selectedSerie].colorsTaster[newValue].colorsRahmen.findIndex(d => d.isDefault);
        saveIdTracking('btn_switch_style', 'btn_switch_style_color_' + schalterdesigns[uiState.selectedSerie].name);
        index2 = index2 > 0 ? index2 : 0;
        updateUiState({
            ...uiState,
            selectedColorTaster: newValue,
            selectedColorRahmen: index2,
            designChanged: uiState.selectedDesignList.length < 3 ? true : uiState.designChanged
        });
    };
    const handleChangeColor2 = (event, newValue) => {
        saveIdTracking('btn_switch_style', 'btn_switch_style_outer_color_' + schalterdesigns[uiState.selectedSerie].name);
        updateUiState({
            ...uiState,
            selectedColorRahmen: newValue,
            designChanged: uiState.selectedDesignList.length < 3 ? true : uiState.designChanged
        });
    };

    function getIcon(id){
        return GenerateImagePath.getPath(id);
    }

    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : floor)});
    }
    function generateTasterColorTabs(schalterdesign){
        return schalterdesign.colorsTaster.map((item, ix) => <Tab id={"btn_switch_style_color_" + schalterdesign.name} className={"btn_switch_style"} key={"taster_" + ix} label={item.name} icon={<img src={getIcon(item.colorId)} alt="" style={{width: "60px"}}/>} />)
    }
    function generateRahmenColorTabs(schalterdesign, colorsRahmen){
        return colorsRahmen.map((item, ix) => <Tab id={"btn_switch_style_outer_color_" + schalterdesign.name} className={"btn_switch_style"} key={"rahmen_" + ix} label={item.name} icon={<img src={getIcon(item.colorId)} alt="" style={{width: "60px"}}/>} />)
    }
    const ColorButtonStyle = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--color1)",
            '&:hover': {
                backgroundColor:"var(--color1)",
            },
            borderRadius: 0,
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            textTransform: "inherit",
            display: "flex",
            flex: "1 1 auto",
            whiteSspace: "nowrap",
            width: "auto",
            maxWidth: "calc(49% - 40px)",
            margin: "auto 1% 0 40px",
            maxHeight: "60px",
            '&.disabled': {
                backgroundColor: "var(--grau6)",
                pointerEvents: "none",
                cursor: "not-allowed"
            }
        },
    }))(Button);
    function replaceOldDesign (od, nd) {
        const oldDesign = {
            selectedColor1: schalterdesigns[od.selectedSerie].colorsTaster[od.selectedColorTaster],
            selectedColor2: schalterdesigns[od.selectedSerie].colorsTaster[od.selectedColorTaster].colorsRahmen[od.selectedColorRahmen],
            selectedSerie: schalterdesigns[od.selectedSerie]
        };
        const newDesign = {
            selectedColor1: schalterdesigns[nd.selectedSerie].colorsTaster[nd.selectedColorTaster],
            selectedColor2: schalterdesigns[nd.selectedSerie].colorsTaster[nd.selectedColorTaster].colorsRahmen[nd.selectedColorRahmen],
            selectedSerie: schalterdesigns[nd.selectedSerie]
        };
        project = {...project, floors: project.floors.map(floor => {
            const newFloor = {
                ...floor,
                rooms: [...floor.rooms.map(r => {
                    const check = oldDesign.selectedSerie.id === r.selectedDesign.selectedSerie.id
                        && oldDesign.selectedColor1.colorId === r.selectedDesign.selectedColor1.colorId
                        && oldDesign.selectedColor2.colorId === r.selectedDesign.selectedColor2.colorId;
                    if (!check) { return r; }
                    return { ...r, selectedDesign: newDesign };
                })]
            };
            return newFloor;
        })};
        updateProject(project);
    }
    function addNewDesign() {
        saveIdTracking('btn_project_cta', 'btn_use_switch_design');
        if (uiState.designColEdit !== null) {
            const oldDesign = uiState.selectedDesignList.filter((i, idx) => idx === uiState.designColEdit)[0];
            const newDesign = {
                selectedSerie: uiState.selectedSerie,
                selectedColorTaster: uiState.selectedColorTaster,
                selectedColorRahmen: uiState.selectedColorRahmen
            };
            if (oldDesign) {
                updateUiState({
                    ...uiState,
                    selectedDesignList: uiState.selectedDesignList.map((i, idx) =>
                        idx !== uiState.designColEdit
                            ? i
                            : {
                                ...i,
                                selectedSerie: uiState.selectedSerie,
                                selectedColorTaster: uiState.selectedColorTaster,
                                selectedColorRahmen: uiState.selectedColorRahmen
                            }),
                    designChanged: false,
                    designColEdit: null
                });
                replaceOldDesign(oldDesign, newDesign);
            }
        } else {
            updateUiState({
                ...uiState,
                selectedDesignList: [
                    ...uiState.selectedDesignList,
                    {selectedSerie: uiState.selectedSerie, selectedColorTaster: uiState.selectedColorTaster, selectedColorRahmen: uiState.selectedColorRahmen}
                ],
                designChanged: false,
                designCount: uiState.designCount + 1,
            });
        }
    }
    function AddNewStyleButton() {
        return <ColorButtonStyle id={"btn_use_switch_design"}
            onClick={e => {addNewDesign()}}
            className={"btn_project_cta" + (!uiState.designChanged ? " disabled" : '')}
        ><img src={arrowDown} style={{marginRight: "14px", position: "relative", top: "-1px"}} alt={""} /> Design hinzufügen</ColorButtonStyle>;
    }
    function getMaxCount(schalterdesign) {
        const array1 = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen;
        const array2 = schalterdesigns[uiState.selectedSerie].colorsTaster;
        return Math.max(array1.length, array2.length);
    }
    function generateCompositeImageWithRahmenColorTabs(schalterdesign, inline = false){
        const max = getMaxCount(schalterdesign);
        let cwidth = 350;
        if (max > 8) { cwidth = 725; }
        if (max <= 8) { cwidth = 725; }
        if (max <= 7) { cwidth = 600; }
        if (max <= 6) { cwidth = 525; }
        if (max <= 5) { cwidth = 475; }
        if (max <= 4) { cwidth = 400; }
        if (max <= 3) { cwidth = 350; }
        return <div>
            <div className={"composite-container"} style={{paddingTop: inline ? '35px': '20px', paddingLeft: '0'}}>
                {/*<div className={uiState.selectedColorRahmen > 0 ? "arrow-container" : "arrow-container button-width"}>*/}
                {/*    <button className={uiState.selectedColorRahmen > 0 ? "arrow-left button" : "arrow-left button hidden"} onClick={() => {changeRahmen('prev', schalterdesign.colorsTaster[uiState.selectedColorTaster].colorsRahmen.length)}}>*/}
                {/*        &#x276E;*/}
                {/*        <div className={"tooltip"}>Vorheriger Rahmen</div>*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className={"compositeImage"} style={{minWidth:"300px"}}>
                    {generateCompositeImage("100%", uiState.selectedSerie, uiState.selectedColorTaster, uiState.selectedColorRahmen, undefined, true)}
                </div>
                <div className={"design-tabs"} style={{display: "flex", flexDirection: "column", height: "300px", flexGrow: "1"}}>
                    <div className={""} style={{float: "none", width: "100%", marginBottom: "15px"}}>
                        <div style={{marginTop: 0, display: "flex", alignItems: "center"}}>
                            <div style={{minWidth: cwidth + 'px', maxWidth: cwidth + 'px', width: cwidth + 'px'}}>
                                <Tabs
                                    value={uiState.selectedColorTaster}
                                    onChange={handleChangeColor1}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    {generateTasterColorTabs(schalterdesigns[uiState.selectedSerie])}
                                </Tabs>
                            </div>
                            <SelectedTasterText></SelectedTasterText>
                        </div>
                    </div>
                    <div style={{marginTop: "15px", display: "flex", alignItems: "center"}}>
                        <div style={{minWidth: cwidth + 'px', maxWidth: cwidth + 'px', width: cwidth + 'px'}}>
                            <Tabs
                                value={uiState.selectedColorRahmen}
                                onChange={handleChangeColor2}
                                variant="scrollable"
                                scrollButtons="on"
                                indicatorColor="primary"
                                textColor="primary"
                            >
                                {generateRahmenColorTabs(schalterdesigns[uiState.selectedSerie], schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen)}
                            </Tabs>
                        </div>
                        <SelectedRahmenText></SelectedRahmenText>
                    </div>
                    <div style={{marginTop: "15px", display: "flex", flexDirection: "row", alignItems: "flex-end", flexGrow: "1", paddingBottom: "22px"}}>
                        <AddNewStyleButton></AddNewStyleButton>
                        <div style={{width: "48%", margin: "auto 5px 0", display: uiState.designCount > 2 ? "block" : "none"}}>Maximale Anzahl an Designs erreicht. Bitte zunächst ein Design löschen oder durch ein anderes Design ersetzen.</div>
                    </div>
                </div>
            </div>
            {!inline ? <div className={"description"} style={{maxWidth: "300px", margin: "15px auto"}}>{getInfoBoxSelectedSerie(true)}</div> : null}
        </div>;
    }
    function deleteDesignColumn(idx = -1) {
        if (idx < 1 && uiState.selectedDesignList.length === 1) { return false; }
        const design = uiState.selectedDesignList[idx];
        const serie = schalterdesigns[design.selectedSerie];
        const color1 = serie.colorsTaster[design.selectedColorTaster];
        const color2 = color1.colorsRahmen[design.selectedColorRahmen];
        const fallDesign = uiState.selectedDesignList[idx > 0 ? (idx - 1) : (idx + 1)];
        const fallSerie = schalterdesigns[fallDesign.selectedSerie];
        const fallColor1 = fallSerie.colorsTaster[fallDesign.selectedColorTaster];
        const fallColor2 = fallColor1.colorsRahmen[fallDesign.selectedColorRahmen];
        const newDesign = {
            selectedColor1: fallColor1,
            selectedColor2: fallColor2,
            selectedSerie: fallSerie
        };
        const newProjectFloors = [];
        project = {
            ...project,
            floors: project.floors.map(floor => {
                const newFloor = {
                    ...floor,
                    rooms: [...floor.rooms.map(r => {
                        const check = r.selectedDesign.selectedSerie.id === serie.id
                            && r.selectedDesign.selectedColor1.colorId === color1.colorId
                            && r.selectedDesign.selectedColor2.colorId === color2.colorId;
                        if (!check) { return r; }
                        return {...r, selectedDesign: newDesign};
                    })]
                };
                newProjectFloors.push(newFloor);
                return newFloor;
            })
        };
        updateProject({...project});
        const newCount = uiState.selectedDesignList.length - 1;
        const newState = {
            ...uiState,
            selectedDesignList: uiState.selectedDesignList.filter((i, iidx) => iidx !== idx),
            designChanged: false,
            designCount: newCount,
            designColEdit: null,
        };
        updateUiState(newState);
    }
    function editDesignColumn(idx) {
        updateUiState({
            ...uiState,
            designColEdit: idx,
            designChanged: true,
        });
    }
    function cancelEditDesignColumn() {
        updateUiState({
            ...uiState,
            designColEdit: null,
            designChanged: false
        });
    }
    function mayDelete(selectedSerie, selectedColor1, selectedColor2) {
        let found = false;
        project.floors.forEach(floor => {
            floor.rooms.forEach(room => {
                const check = room.selectedDesign.selectedSerie.id === schalterdesigns[selectedSerie].id
                    && room.selectedDesign.selectedColor1.colorId === schalterdesigns[selectedSerie].colorsTaster[selectedColor1].colorId
                    && room.selectedDesign.selectedColor2.colorId === schalterdesigns[selectedSerie].colorsTaster[selectedColor1].colorsRahmen[selectedColor2].colorId;
                found = found || check;
            });
        });
        return !found;
    }
    function generateCompositeImage(width, selectedSerie, selectedColor1, selectedColor2, idx, noEdit = false){
        const canBeDeleted = mayDelete(selectedSerie, selectedColor1, selectedColor2);
        return <div className={"design-column-header" + (uiState.designColEdit === idx ? " active" : "")}>
            {generateCompositeImageFromObjects(width, schalterdesigns[selectedSerie].colorsTaster[selectedColor1], schalterdesigns[selectedSerie].colorsTaster[selectedColor1].colorsRahmen[selectedColor2])}
            {!noEdit ? <>
                <div className={"on-hover" + (uiState.designColEdit !== null ? " hidden" : "")}>
                    <img src={colEdit} alt={""} onClick={() => {editDesignColumn(idx)}}/>
                    {idx > 0 || uiState.designCount > 1 ?
                        <img src={canBeDeleted ? colDelete : colDeleteGray} alt={""}
                             onClick={(e) => {canBeDeleted ? deleteDesignColumn(idx) : e.preventDefault()}}
                             title={canBeDeleted ? "" : "Zum Löschen darf das Design keinem Raum mehr zugewiesen sein."} />
                        : null}
                </div>
                {uiState.designColEdit !== null && idx === uiState.designColEdit ? <div className={"cancel-edit"}>
                    <img src={iconCancel} alt={""} onClick={() => {cancelEditDesignColumn()}}
                         style={{transform: "rotate(45deg)", width: "20px", height: "20px"}} />
                </div> : null}
            </> : null
            }
        </div>;
    }
    function generateCompositeImageFromObjects(width, selectedColor1, selectedColor2){
        return <div className={"imageTasterAndFrame"} style={{display:"inline-block", width:width, position:"relative"}}>
            <img className={"imageTaster"} src={getIcon(selectedColor1.colorId)} style={{width:"100%", verticalAlign:"bottom"}} alt={""}/>
            <img className={"imageFrame"} src={getIcon(selectedColor2.colorId)} style={{width:"100%", position:"absolute", top:0, left:0}} alt={""}/>
        </div>
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--color2)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '250px',
            minWidth: '150px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    }))(Tooltip);
    const InfoOutlinedIconCustom = withStyles((theme) => ({
        root: {
            color: "var(--grau3)",
            fontSize: "18px",
            verticalAlign: "middle",
            paddingLeft: "5px"
        },
    }))(InfoOutlinedIcon);
    function SelectedTasterText() {
        var textTaster = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorName;
        const finishNameT = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].finishName;
        textTaster += (finishNameT) ? ", " + finishNameT : "";
        textTaster += ", " + schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].materialName;
        return <div className={"design-selection-text"}>{textTaster}</div>;
    }
    function SelectedRahmenText() {
        var textRahmen = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].colorName;
        const finishNameR = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].finishName;
        textRahmen += (finishNameR) ? ", " + finishNameR : "";
        textRahmen += ", " + schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].materialName;
        return <div className={"design-selection-text"}>{textRahmen}</div>;
    }
    function getInfoBoxSelectedSerie(justText = false){
        var textTaster = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorName;
        const finishNameT = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].finishName;
        textTaster += (finishNameT) ? ", " + finishNameT : "";
        textTaster += ", " + schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].materialName;
        var textRahmen = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].colorName;
        const finishNameR = schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].finishName;
        textRahmen += (finishNameR) ? ", " + finishNameR : "";
        textRahmen += ", " + schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen].materialName;
        const toolTipText = <div className={justText ? "noTextTransform" : ""}>
            <div className={"row"}>
                <div className={"col-3"}>Designlinie:</div>
                <div className={"col-7"}>{schalterdesigns[uiState.selectedSerie].name}</div>
            </div>
            <div className={"row"}>
                <div className={"col-3"}>Taster:</div>
                <div className={"col-7"}>{textTaster}</div>
            </div>
            <div className={"row"}>
                <div className={"col-3"}>Rahmen:</div>
                <div className={"col-7"}>{textRahmen}</div>
            </div>
        </div>;
        if (justText) { return toolTipText; }
        return <HtmlTooltip title={toolTipText}>
            <InfoOutlinedIconCustom />
        </HtmlTooltip>;
    }
    function generateSelectedColumnHeader(idx){
        const selectedSerie = schalterdesigns[uiState.selectedDesignList[idx].selectedSerie];
        const selectedColor1 = selectedSerie.colorsTaster[uiState.selectedDesignList[idx].selectedColorTaster];
        const selectedColor2 = selectedColor1.colorsRahmen[uiState.selectedDesignList[idx].selectedColorRahmen];
        if(selectedSerie !== undefined && selectedColor1 !== undefined && selectedColor2 !== undefined) {
            var textTaster = selectedColor1.colorName;
            const finishNameT = selectedColor1.finishName;
            textTaster += (finishNameT) ? ", " + finishNameT : "";
            textTaster += ", " + selectedColor1.materialName;
            var textRahmen = selectedColor2.colorName;
            const finishNameR = selectedColor2.finishName;
            textRahmen += (finishNameR) ? ", " + finishNameR : "";
            textRahmen += ", " + selectedColor2.materialName;
            const toolTipText = <div style={{padding: '5px 5px'}}>
                <div className={"row"}>
                    <div className={"col-2"}>Designlinie:</div>
                    <div className={"col-2"}>{selectedSerie.name}</div>
                </div>
                <div className={"row"}>
                    <div className={"col-2"}>Taster:</div>
                    <div className={"col-2"}>{textTaster}</div>
                </div>
                <div className={"row"}>
                    <div className={"col-2"}>Rahmen:</div>
                    <div className={"col-2"}>{textRahmen}</div>
                </div>
            </div>;
            const ssNr = uiState.selectedDesignList[idx].selectedSerie;
            const sc1Nr = uiState.selectedDesignList[idx].selectedColorTaster;
            const sc2Nr = uiState.selectedDesignList[idx].selectedColorRahmen;
            return <HtmlTooltip title={toolTipText}>
                <div style={{padding: "5px"}}>{generateCompositeImage("50px", ssNr, sc1Nr, sc2Nr, idx)}</div>
            </HtmlTooltip>;
        }

        return null;
    }
    return <>
        <div className={"gray-border wMargin design-block"} style={{marginTop: '0'}}>
            <div className="header-item-new-up" style={{paddingTop: "0"}} id={"checkForSticky"}>Gewünschte Designlinie, Farbe und Materialität auswählen.</div>
            <div className={"clearfix"} style={{marginTop: '15px'}}>
                <div className={"SerienSelectBox"}>
                    <div className={"design-title"} style={{color: 'var(--textColor)', marginBottom: '33px'}}>Sie möchten unterschiedliche Schalterdesigns in verschiedenen Räumen einsetzen?<br/>
                        Kein Problem - Sie können bis zu drei unterschiedliche Schalterdesigns konfigurieren und den Räumen zuweisen.</div>
                    <Tabs
                        value={uiState.selectedSerie}
                        onChange={handleChangeSerie}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        {schalterdesigns.map(item => <Tab id={"btn_switch_style_type_" + item.name} key={item.id} label={item.name} icon={<img src={getIcon(item.id)} alt="" style={{width: "60px"}}/>} />)}
                    </Tabs>
                </div>
            </div>
            <div className={"clearfix"}>
                <div className={"composite-with-rahmen"}>
                    {generateCompositeImageWithRahmenColorTabs(schalterdesigns[uiState.selectedSerie], true)}
                </div>
            </div>
        </div>
        <div className={"gray-border design-block"}>
            {/*<StickyRow></StickyRow>*/}
            <div className={"row"} style={{marginTop: "0", height: "80px"}}>
                <div className={"header-item-new-up"} style={{minWidth: "400px", maxWidth: "400px"}}>
                    Auswahl zuordnen<br/>
                    <div className={"subtitle"} style={{color: 'var(--textColor)', marginTop: '10px'}}>Konfigurierte Schalterdesigns den Räumen zuordnen.</div>
                </div>
                {uiState.selectedDesignList[0] !== undefined
                    ? <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}>{generateSelectedColumnHeader(0)}</div>
                    : <div className={"text-center col-1"} style={{padding: "5px"}}></div>}
                {uiState.selectedDesignList[1] !== undefined
                    ? <div className={"text-center col-1"} style={{padding: "5px"}}>{generateSelectedColumnHeader(1)}</div>
                    : <div className={"text-center col-1"} style={{padding: "5px"}}></div>}
                {uiState.selectedDesignList[2] !== undefined
                    ? <div className={"text-center col-1"} style={{padding: "5px"}}>{generateSelectedColumnHeader(2)}</div>
                    : <div className={"text-center col-1"} style={{padding: "5px"}}></div>}
            </div>
            {project.floors.map((floor, idx) =>
                <FloorDesignComponent
                    key={floor.id}
                    floor={floor}
                    data={schalterdesigns}
                    isFirst={idx === 0}
                    updateFloor={updateFloor}
                    generateCompositeImage={generateCompositeImageFromObjects}
                    currentDesign={
                        {
                            selectedSerie: schalterdesigns[uiState.selectedSerie],
                            selectedColor1: schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster],
                            selectedColor2: schalterdesigns[uiState.selectedSerie].colorsTaster[uiState.selectedColorTaster].colorsRahmen[uiState.selectedColorRahmen]
                        }
                    }
                    uiState={uiState}
                    schalterdesigns={schalterdesigns}
                />
            )}
        </div>
    </>

}

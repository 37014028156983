import {Navigate, useParams} from "react-router-dom";
import ProjectComponent from "./ProjectComponent";
import React, {useEffect} from "react";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import {v4 as uuidv4} from "uuid";
import roomList from "../data/availableRooms.json";
import initialProjectData from "../data/initialProjectData.json";
import knx_schalterdesigns from "../data/knx_schalterdesign.json";
import easy_schalterdesigns from "../data/easy_schalterdesign.json";
import konventionell_schalterdesigns from "../data/konventionell_schalterdesign.json";
import {ProjectSaveService} from "../services/ProjectSaveService";
import ReactGA from 'react-ga4';
import gaEventTracker from "../services/gaEventTracker";
import TagManager from 'react-gtm-module';
// import easy_ausstattung from "../data/easy_austattung_produkt.json";
// import knx_ausstattung from "../data/knx_austattung_produkt.json";
// import products from "../data/products.json";
// import BME from "../data/Hager_Artikelstamm_2024-04_BMEcat_ETIM8_240118.xml";

export default function StartComponent(){
    const [trackingId, setTrackingId] = React.useState(null);
    const [currentStep, setCurrentStep] = React.useState(1);
    const params = useParams();
    const params2 = loadParams(params);
    const [isExpert] = React.useState(loadIsExpert);
    const [loadedCode, setLoadedCode] = React.useState(loadCode);
    const projectSelectList = [...initialProjectData];
    const [project, setProject] = React.useState(initProject);
    const [uiState, setUiState] = React.useState(getInitialUiState);
    // const TRACKING_ID = 'G-6V7V265CJ3';
    // const TRACKING_ID = 'G-K77280RG99';
    // const TRACKING_ID = 'GT-TQDGHRD';
    // const hagerGlobal = 'G-PV0S9027V6';
    // const hagerLand = 'G-YW49J5RBRM';
    // const hagerNeu = 'GTM-MDQZN4C';
    const GaCode = process.env.REACT_APP_GA;
    console.log('GA-Code', GaCode);
    // checkProducts();

    // ReactGA.initialize([{trackingId: hagerLand}, {trackingId: hagerGlobal}, {trackingId: hagerNeu}]);
    //ReactGA.initialize([{trackingId: GaCode}]);
    //TagManager.initialize({
    //    gtmId: GaCode
    //});

    function setCurrentStepUrl(page) {
        let paramList = (params['*']).split('/');
        if (paramList.length > 1) { params['*'] = (isExpert ? 'expert' : 'customer') + '/step_' + page; }
        window.history.replaceState(null, 'FS Quickplaner - Schritt ' + page, (isExpert ? '/expert' : '/customer') + '/step_' + page);
        setCurrentStep(page);
    }

    function updateQueryParams(str, title) {
        window.history.replaceState(null, title, (isExpert ? '/expert' : '/customer') + '/' + str);
    }

    function loadParams(params){
        // console.log('params', params, window.location.pathname)
        if(params['*'] !== undefined){
            let paramList = (params['*']).split('/');
            switch (paramList.length) {
                case 1:
                    return {isExpert: (paramList[0] === 'expert'), code: undefined};
                case 2:
                    if (['step_1', 'step_2', 'step_3', 'step_4', 'step_5', 'step_6'].includes(paramList[1])) {
                        // let lst = window.location.pathname.split('/');
                        // lst.splice(0, 1); // removing ''
                        // console.log('paramlist', paramList, currentStep)
                        // if (lst.length) {
                            switch (paramList[1]) {
                                case 'step_1': if (currentStep !== 1) { setCurrentStep(1); } break;
                                case 'step_2': if (currentStep !== 2) { setCurrentStep(2); } break;
                                case 'step_3': if (currentStep !== 3) { setCurrentStep(3); } break;
                                case 'step_4': if (currentStep !== 4) { setCurrentStep(4); } break;
                                case 'step_5': if (currentStep !== 5) { setCurrentStep(5); } break;
                                case 'step_6':
                                    if (currentStep !== 6) { setCurrentStep(6); }
                                    break;
                                default: break;
                            }
                        // }
                        paramList.splice(1, 1);
                    }
                    // console.log('currentStep', currentStep)
                    return {isExpert: (paramList[0] === 'expert'), code: paramList[1]};
                default:
                    return {isExpert: true, code: undefined};
            }
        }
    }

    function loadCode(){
        if(params2.code !== undefined && params2.code.length > 0){
            return params2.code;
        }
        return undefined;
    }
    function loadIsExpert(){
        if(params2.isExpert !== undefined){
            return params2.isExpert;
        }
        return undefined;
    }

    function initProject(projectType = "easy"){
        let projectTypes = {
            knx: "KNX",
            easy: "HAGER_KNX_EASY",
            konventionell: "CONVENTIONAL"
        };

        const trackingProjectType = projectTypes[projectType];
        // need projectType because by projectType change projectSelectList[0] does not have this property
        // and the reseted project type will be 'easy'
        GenerateRoomToAddService.resetDefaultDesignConfig();
        if (!trackingId) {
            ProjectSaveService.saveTracking({
                trackingId: null, projectType: trackingProjectType, step: 1, b2b: isExpert ? true : false
            }, setTrackingId);
        } else {
            ProjectSaveService.saveTracking({
                trackingId: trackingId.trackingId, projectType: trackingProjectType, b2b: isExpert ? true : false
            },undefined, trackingId);
        }
        return {
            ...generateProject({...projectSelectList[0], projectType: projectSelectList[0].projectType ? projectSelectList[0].projectType : projectType}, true),
            projectType: projectType, // "easy",
            selectedMusterIx: 0,
            projectName: "",
            isExpert,
            showStep6Modal: true
        };
    }
    function generateProject(projectIn, resetSelectedDesign = false){
        const selectedLevel = typeof projectIn.selectedLevel != "undefined" ? projectIn.selectedLevel : 1;
        const floors = projectIn.floors;
        const schalterdesign = getSchalterDesign(projectIn.projectType);
        const floorToUse = floors.map( i => {
                return {
                    ...i,
                    id: i.id ? i.id : uuidv4(),
                    rooms: i.rooms.map(r => {
                        return {
                            ...r,
                            id: r.id ? r.id : uuidv4(),
                            selectedDesign: resetSelectedDesign
                                ? GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign)
                                : (r.selectedDesign ? r.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign)),
                            selectedEquipment: getDefaultEquipmentForSelectedLevel(r.type, selectedLevel)
                        }
                    })
                };
            }
        );
        return {
            selectedLevel: selectedLevel,
            ...projectIn,
            floors: floorToUse
        };
    }
    function getDefaultEquipmentForSelectedLevel(type, selectedLevel){
        const list1 = roomList.filter(rl => (rl.type === type));
        const list2 = list1.length > 0 ? (list1[0]).defaultEquipment.filter(se => (se.level === selectedLevel)) : [];
        return list2.length > 0 ? list2[0].values : {};
    }
    function setSelectedProject(projectIn){
        // const gaTracker = gaEventTracker('Projekt');
        const pr = projectSelectList[projectIn.selectedMusterIx];
        switch (pr.typeId) {
            case 1: saveIdTracking('ob_building_type', 'ob_building_type_small_house'); break;
            case 2: saveIdTracking('ob_building_type', 'ob_building_type_large_house'); break;
            case 3: saveIdTracking('ob_building_type', 'ob_building_type_small_flat'); break;
            case 4: saveIdTracking('ob_building_type', 'ob_building_type_large_flat'); break;
            default: break;
        }
        setProject(generateProject({...projectIn, projectName: project.projectName, projectType: project.projectType}));
    }
    function getSchalterDesign(projectType){
        switch (projectType) {
            case "knx":
                return knx_schalterdesigns;
            case "easy":
                return easy_schalterdesigns;
            case "konventionell":
                return konventionell_schalterdesigns;
            default:
                return easy_schalterdesigns;
        }
    }
    function getInitialUiState(pType = ''){

        const schalterdesigns = getSchalterDesign(pType || project.projectType);
        var serieIx = schalterdesigns.findIndex(d => d.isDefault);
        serieIx = serieIx > 0 ? serieIx : 0;
        var tasterIx = schalterdesigns[serieIx].colorsTaster.findIndex(d => d.isDefault);
        tasterIx = tasterIx > 0 ? tasterIx : 0;
        var rahmenIx = schalterdesigns[serieIx].colorsTaster[tasterIx].colorsRahmen.findIndex(d => d.isDefault);
        rahmenIx = rahmenIx > 0 ? rahmenIx : 0;

        return {
            selectedSerie: serieIx,
            selectedColorTaster: tasterIx,
            selectedColorRahmen: rahmenIx,
            designChanged: false,
            designCount: 1,
            selectedDesignList: [{selectedSerie: serieIx, selectedColorTaster: tasterIx, selectedColorRahmen: rahmenIx}],
            designColEdit: null,
        };
    }
    function startWithProject(projectIn){
        GenerateRoomToAddService.resetDefaultDesignConfig();
        setProject(projectIn);
        setCurrentStepUrl(5);
        if (projectIn.isExpert !== undefined && projectIn.isExpert === true) {
            setCurrentStepUrl(6);
        }
    }
    function saveIdTracking(cls, id) {
        const gaTracker = gaEventTracker('Projekt');
        gaTracker(cls, id);
        console.log('saved ga tracking for', cls, 'with value', id);
    }

    if(loadedCode !== undefined && loadedCode.length > 0){
        setTrackingId(null);
        ProjectSaveService.saveTracking({trackingId: null, step: 1, b2b: isExpert ? true : false}, setTrackingId);
        setTimeout(() => {
            ProjectSaveService.loadProject(loadedCode, startWithProject);
            setLoadedCode(undefined);
        }, 500);
    }

    // function checkProducts() {
    //     let arr = {};
    //     easy_ausstattung.map(item => {
    //         for(const key in item) {
    //             arr[item[key]] = 1;
    //         }
    //     });
    //     knx_ausstattung.map(item => {
    //         for(const key in item) {
    //             arr[item[key]] = 1;
    //         }
    //     });
    //     const missing = [];
    //     for(const [key, value] of Object.entries(arr)) {
    //         const res = products.filter(i => String(i["Artikelnummer"]) === String(key))[0];
    //         if (!res && !["VORAUSSETZUNG", "serie"].includes(String(key))) { missing.push(key); }
    //     }
    //     fetch(BME)
    //     .then((xmlStr) => xmlStr.text())
    //     .then((xmlStr) => {
    //         const parser = new DOMParser();
    //         const xmlDoc = parser.parseFromString(xmlStr, "text/xml");
    //         console.log('missing', missing, xmlDoc.getElementsByTagName('PRODUCT'));
    //         const prList = [...xmlDoc.getElementsByTagName('PRODUCT')];
    //         const newProducts = [];
    //         missing.map(pid => {
    //             const found = prList.filter(i => String(i.children[1].children[3].childNodes[0].data) === String(pid))[0];
    //             if (found) {
    //                 console.log('found', pid, found, found.childNodes, products.length)
    //                 newProducts.push({
    //                     "Artikelnummer": pid,
    //                     "Bezeichnung": found.childNodes[3].childNodes[1].childNodes[0].nodeValue,
    //                     "Preis": found.childNodes[9].childNodes[5].childNodes[1].childNodes[0].nodeValue * 100,
    //                     "UID-Pro": "",
    //                     "Gtin": found.childNodes[3].childNodes[5].childNodes[0].nodeValue,
    //                     "kategorie": "",
    //                     "BEG-20": 0,
    //                     "BEG-35": 0
    //                 });
    //             } else {
    //                 console.log('not found', pid)
    //                 newProducts.push({
    //                     "Artikelnummer": pid,
    //                     "Bezeichnung": '',
    //                     "Preis": '',
    //                     "UID-Pro": "",
    //                     "Gtin": '',
    //                     "kategorie": "",
    //                     "BEG-20": 0,
    //                     "BEG-35": 0
    //                 });
    //             }
    //         });
    //         console.log('json', JSON.stringify(newProducts));
    //     })
    //     .catch((e) => console.error(e));
    // }

    return <>{params2.code !== undefined ? <Navigate to={isExpert ? "/expert" : "/customer"}/> : <ProjectComponent
        isExpert={isExpert}
        project={project}
        setProject={setProject}
        setSelectedProject={setSelectedProject}
        initProject={initProject}
        generateProject={generateProject}
        currentStep={currentStep}
        setCurrentStep={setCurrentStepUrl}
        projectSelectList={projectSelectList}
        getSchalterDesign={getSchalterDesign}
        uiState={uiState}
        setUiState={setUiState}
        getInitialUiState={getInitialUiState}
        startWithProject={startWithProject}
        updateQueryParams={updateQueryParams}
        saveIdTracking={saveIdTracking}
        trackingObj={trackingId}
        setTrackingId={setTrackingId}
    />}</>

}

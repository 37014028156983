import React from "react";
import CountBox from "./CountBox/CountBox";
import LabelBox from "./LabelBox/LabelBox";

export default function RoomComponent({room, updateRoom, deleteRoom}){


    function rename(name){
        updateRoom({...room, name: name});
    }
    function onDelete(e){
        deleteRoom(room);
    }
    function setDoorCount(value){
        updateRoom({...room, doorCount: value});
    }
    function setWindowCountCount(value){
        updateRoom({...room, windowCount: value});
    }
    function setHeatingCountCount(value){
        updateRoom({...room, heatingCount: value});
    }
    function setFloorheatingCountCount(value){
        updateRoom({...room, floorheatingCount: value});
    }

    return <div className="roomRow row">
        <div className="col-4 contextBoxHover" style={{position: "relative"}}>
            <LabelBox value={room.name} setValue={rename} deleteItem={onDelete}/>
        </div>
        <div className="text-center col-2"><CountBox minValue={0} maxValue={15} value={room.doorCount} setValue={setDoorCount}/></div>
        <div className="text-center col-2"><CountBox minValue={0} maxValue={15} value={room.windowCount} setValue={setWindowCountCount}/></div>
        <div className="text-center col-2"><CountBox minValue={0} maxValue={15} value={room.heatingCount} setValue={setHeatingCountCount}/></div>
        <div className="text-center col-2"><CountBox minValue={0} maxValue={15} value={room.floorheatingCount} setValue={setFloorheatingCountCount}/></div>
    </div>;
}

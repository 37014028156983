
import {Navigate, Route, Routes} from "react-router-dom";
import StartComponent from "./StartComponent";


export default function RouterComponent(){

    return <Routes>
        <Route path={"/"} element={<Navigate to={"/expert"} />}/>
        <Route path={"*"} element={<StartComponent />}/>
    </Routes>

}

import React from "react";
import StarIcon from "@material-ui/icons/Star";
import {Tooltip, withStyles} from "@material-ui/core";
import radioUnchecked from "../images/figma/radio_unchecked.svg"
import radioChecked from "../images/figma/radio_checked.svg"
import socketIcon from "../images/figma/socket.svg";
import switchIcon from "../images/figma/switch.svg";
import boxIcon from "../images/figma/box.svg";
import checkMark from "../images/figma/check_mark.svg";
import temperature from "../images/figma/temperature.svg";
import shutter from "../images/figma/shutter_control.svg";
import smoke from "../images/figma/smoke.svg";
import audio from "../images/figma/audio.svg";
import iIcon from "../images/info_icon.svg";

export default function SelectConfLevelComponent({selectedLevel, setLevel, justStars = false}){

    const levelList = [
        {
            id: "one_star",
            value: 1,
            stars: [<StarIcon key="1" className={"blue"} htmlColor={"var(--blue"} />],
            title: "Mindestausstattung",
            description: "Der kosteneffiziente Einstieg in Ihr smartes Gebäude.",
            hoverText1: "Mindestausstattung",
            hoverText2: "Der kosteneffiziente Einstieg in Ihr smartes Gebäude. Ausstattung aller Räume mit Steckdosen, Anschlussdosen und Schaltern nach DIN-Norm. Ausgewählte Räume zusätzlich mit Dimmfunktion. Inklusive Rollladensteuerung für alle Räume mit Fenstern.",
            equipment: [
                {label: "Steckdosen", icon: socketIcon},
                {label: "Schalter", icon: switchIcon},
                {label: "Anschlussdosen", icon: boxIcon},
            ]
        },
        {
            id: "two_star",
            value: 2,
            stars: [<StarIcon key="1" className={"blue"} htmlColor={"var(--blue"} />,<StarIcon key="2" className={"blue"} htmlColor={"var(--blue"} />],
            title: "Standardausstattung",
            description: "Der solide Einstieg in Ihr smartes Gebäude mit Heizungssteuerung.",
            hoverText1: "Standardausstattung",
            hoverText2: "Der solide Einstieg in Ihr smartes Gebäude mit Heizungssteuerung. Mediumausstattung mit Schaltern und Steckdosen, entsprechend Ihrer Designauswahl, in jedem Raum. Dimmmfunktion in allen relevanten Räumen., Smarte Rollladensteuerung an allen Fenstern (außer Keller). Zusätzlich regeln Raumtemperaturregler an der Wand die Temperatur in jedem Raum. ",
            equipment: [
                {label: "Alles in der Mindestausstattung", icon: checkMark},
                {label: "Heizungssteuerung", icon: temperature},
                {label: "Smarte Rollladensteuerung", icon: shutter},
            ]
        },
        {
            id: "three_star",
            value: 3,
            stars: [<StarIcon key="1" className={"blue"} htmlColor={"var(--blue"} />,<StarIcon key="2" className={"blue"} htmlColor={"var(--blue"} />,<StarIcon key="3" className={"blue"} htmlColor={"var(--blue"} />],
            title: "Komfortausstattung",
            description: "Mit Heizungssteuerung und Türkommunikation.",
            hoverText1: "Komfortausstattung",
            hoverText2: "Mit Heizungssteuerung und Türkommunikation Diese Auswahl deckt alle Schalt- und Dimmfunktionen für Beleuchtung, Steckdosen und Verschattung ab Zusätzlichen Komfort bietet die smarte Heizungssteuerung, Audio- oder Videotürkommunikation sorgt für ein Plus an Sicherheit. ",
            equipment: [
                {label: "Alles in der Standardausstattung", icon: checkMark},
                {label: "Smarte Licht-, Rolladen und Heizungssteuerung", icon: checkMark},//, icon: smoke},
                // {label: "Türkommunikation", icon: audio},
            ]
        }
    ];

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--color2)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '350px',
            minWidth: '150px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    }))(Tooltip);

    if (justStars) {
        return <div className="starsBox" style={{float: "right", marginTop: "-3%"}}>
            <HtmlTooltip title={<><p style={{fontWeight: "bold", padding: "0 15px"}}>{levelList[0].hoverText1}</p><p style={{fontWeight: "normal", padding: "0 15px"}}>{levelList[0].description}</p><p style={{fontWeight: "normal", padding: "0 15px"}}>{levelList[0].hoverText2}</p></>}>
                <StarIcon key="1" htmlColor={"var(--color1)"} onClick={e => setLevel(e, 1)}/>
            </HtmlTooltip>
            <HtmlTooltip title={<><p style={{fontWeight: "bold", padding: "0 15px"}}>{levelList[1].hoverText1}</p><p style={{fontWeight: "normal", padding: "0 15px"}}>{levelList[1].description}</p><p style={{fontWeight: "normal", padding: "0 15px"}}>{levelList[1].hoverText2}</p></>}>
                <StarIcon key="1" htmlColor={"var(--color" + (selectedLevel >= 2 ? 1 : 2) + ")"} onClick={e => setLevel(e, 2)}/>
            </HtmlTooltip>
            <HtmlTooltip title={<><p style={{fontWeight: "bold", padding: "0 15px"}}>{levelList[2].hoverText1}</p><p style={{fontWeight: "normal", padding: "0 15px"}}>{levelList[2].description}</p><p style={{fontWeight: "normal", padding: "0 15px"}}>{levelList[2].hoverText2}</p></>}>
                <StarIcon key="1" htmlColor={"var(--color" + (selectedLevel >= 3 ? 1 : 2) + ")"} onClick={e => setLevel(e, 3)}/>
            </HtmlTooltip>
        </div>;
    }
    return <div className="SelectConfLevelComponentWrapper row">
        { levelList.map( (item, ix) =>
            <div id={item.value < 3 ? (item.value < 2 ? 'ob_features_minimal' : 'ob_features_standard') : 'ob_features_comfort'} key={item.id} className={"ob_features col-1 levelSelectBox" + (item.value === selectedLevel ? " active" : "")} onClick={e => setLevel(e, item.value)}>
                <img src={item.value === selectedLevel ? radioChecked : radioUnchecked} width="24" alt="radio" />
                <div className={"title"} style={{display: 'flex'}}>
                    <span style={{display: 'inline-flex', alignItems: 'center'}}>{item.title}</span>
                    <HtmlTooltip title={<div style={{padding: "25px", color: 'var(--blueColor)'}}>
                        <p>{item.hoverText1}</p>
                        <p>{item.hoverText2}</p>
                    </div>}>
                                    <span className="info-icon" style={{display: 'inline-flex'}}>
                                        <img src={iIcon} width="32" height="32" alt={"info"} style={{margin: '0'}} />
                                    </span>
                    </HtmlTooltip>
                </div>
                <div className={"stars-container"}>
                    {item.stars}
                    {item.value < 3
                        ? (item.value < 2
                            ? [<StarIcon key="2" className={"gray"} style={{stroke: "var(--textColor", fill: "transparent"}} />, <StarIcon key="3" className={"gray"} style={{stroke: "var(--textColor", fill: "transparent"}} />]
                            : <StarIcon key="3" className={"gray"} style={{stroke: "var(--textColor", fill: "transparent"}} />
                        )
                        : null
                    }
                </div>
                <div className={"description"}>Die Räume werden ausgestattet mit</div>
                <div className={"equipments"}>
                    {item.equipment.map((f, idx) =>
                        <div className={"equipment"}>
                            <img src={f.icon} width="24" alt="equipment" />
                            <span>{f.label}</span>
                        </div>)
                    }
                </div>
            </div>
        )}
    </div>
}

import React, {useEffect} from "react";
import initialProjectData from '../data/initialProjectData.json';
import knx_equipmentData_step2 from '../data/knx_ausstattungen_step2.json';
import easy_equipmentData_step2 from '../data/easy_ausstattungen_step2.json';
import konventionell_equipmentData_step2 from '../data/konventionell_ausstattungen_step2.json';
import knx_equipmentData_step4 from '../data/knx_ausstattungen_step4.json';
import easy_equipmentData_step4 from '../data/easy_ausstattungen_step4.json';
import konventionell_equipmentData_step4 from '../data/konventionell_ausstattungen_step4.json';
import StepOneComponent from "./StepOneComponent";
import StepTwoComponent from "./StepTwoComponent";
import logoSvg from "./../images/figma/logo.svg"
import StepThreeComponent from "./StepThreeComponent";
import StepFourComponent from "./StepFourComponent";
import StepSixComponent from "./StepSixComponent";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import Button from '@material-ui/core/Button';
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import StepFiveComponent from "./StepFiveComponent";
import {ProjectSaveService} from "../services/ProjectSaveService";
import bannerSvg from "../images/figma/banner.png";
import arrowUpSvg from "../images/figma/arrow_up.svg";
import arrowRight from "../images/figma/arrow_right.svg";
import arrowRightGray from "../images/figma/arrow_right_gray.svg";
import gaEventTracker from "../services/gaEventTracker";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectComponent({isExpert = false, project, setProject, setSelectedProject, initProject, generateProject, currentStep, setCurrentStep, projectSelectList, getSchalterDesign, uiState, setUiState, getInitialUiState, startWithProject, updateQueryParams, saveIdTracking, setTrackingId, trackingObj}){

    useEffect(() => {

    }, []);
    const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
    const [resetFAQDialogOpen, setResetFAQDialogOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState('panel1');
    // const [loadProjectOpen, setLoadProjectOpen] = React.useState(false);
    const [projectCode, setProjectCode] = React.useState("");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    let availableSteps = [1,2,3,4,6];
    if(isExpert){
        availableSteps = [1,2,3,4,5,6]
    }

    function navigateToStep(value, isBtn = false){
        if (isBtn) {
            if (currentStep < value) { saveIdTracking('btn_project_nav', 'btn_next_step'); }
            else { saveIdTracking('btn_project_nav', 'btn_previous_step'); }
        }
        else { saveIdTracking('btn_project_nav', 'btn_step_' + value); }
        setCurrentStep(value)
        window.scrollTo(0, 0);
        if (trackingObj) {
            ProjectSaveService.saveTracking({step: value, trackingId: trackingObj.trackingId}, undefined, trackingObj)
        }
    }
    function updateProject(projectIn){
        setProject({...projectIn});
    }

    function getEuqipmentDataStep2(projectType){
        switch (projectType) {
            case "knx":
                return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2);
            case "easy":
                return filterEquipmentForSelectecBuildingType(easy_equipmentData_step2);
            case "konventionell":
                return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2);
            default:
                return [];
        }
    }
    function getEuqipmentDataStep4(projectType){
        switch (projectType) {
            case "knx":
                return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4);
            case "easy":
                return filterEquipmentForSelectecBuildingType(easy_equipmentData_step4);
            case "konventionell":
                return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4);
            default:
                return [];
        }
    }
    function filterEquipmentForSelectecBuildingType(listIn){
        return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData[project.selectedMusterIx].type === l))));
    }

    function renderSwitch(param) {
        if (param > availableSteps.length) { param = 5; }
        // console.log('param', param, availableSteps[param-1], availableSteps)
        switch(availableSteps[param - 1]) {
            case 2:
                return <StepTwoComponent
                    project={project}
                    equipmentData={getEuqipmentDataStep2(project.projectType)}
                    updateProject={updateProject}
                    updateProjectWithNewLevel={p => updateProject(generateProject(p))}
                    updateQueryParams={updateQueryParams}
                    isExpert={isExpert}
                    saveIdTracking={saveIdTracking}
                />;
            case 3:
                return <StepThreeComponent
                    project={project}
                    updateProject={updateProject}
                    uiState={uiState}
                    setUiState={setUiState}
                    updateQueryParams={updateQueryParams}
                    isExpert={isExpert}
                    saveIdTracking={saveIdTracking}
                />;
            case 4:
                return <StepFourComponent
                    project={project}
                    equipmentData={getEuqipmentDataStep4(project.projectType)}
                    updateProject={updateProject}
                    updateProjectWithNewLevel={p => updateProject(generateProject(p))}
                    updateQueryParams={updateQueryParams}
                    isExpert={isExpert}
                    saveIdTracking={saveIdTracking}
                />;
            case 5:
                return <StepFiveComponent
                    project={project}
                    updateProject={updateProject}
                    isExpert={isExpert}
                    updateQueryParams={updateQueryParams}
                />;
            case 6:
                return <StepSixComponent
                    project={project}
                    trackingObj={trackingObj}
                    equipmentData2={getEuqipmentDataStep2(project.projectType)}
                    equipmentData4={getEuqipmentDataStep4(project.projectType)}
                    updateProject={updateProject}
                    isExpert={false}
                    isExpertForSave={isExpert}
                    updateQueryParams={updateQueryParams}
                    saveIdTracking={saveIdTracking}
                />;
            default:
            case 1:
                return <StepOneComponent
                    project={project}
                    projectSelectList={projectSelectList}
                    setSelectedProject={setSelectedProject}
                    updateProject={updateProject}
                    schalterdesign={getSchalterDesign(project.projectType)}
                    startProject={startProject}
                    updateQueryParams={updateQueryParams}
                    isExpert={isExpert}
                    saveIdTracking={saveIdTracking}
                />;
        }
    }
    function startProject(projectType, navigate = true, resetDesign = false) {
        GenerateRoomToAddService.resetDefaultDesignConfig();
        updateProject(
            {...initProject(projectType), projectType: projectType, projectName: project.projectName, isExpert}
        );
        setUiState(getInitialUiState(projectType));
        if (navigate) { navigateToStep(1); }
        // const gaTracker = gaEventTracker('Projekt');
        // gaTracker('ob_project_type', projectType);
        saveIdTracking('ob_project_type', projectType === 'easy' ? 'ob_project_type_smart' : 'ob_project_type_conventional')
    }

    const ColorButton1 = withStyles((theme) => ({
        root: {
            color: "var(--color1)",
            backgroundColor: "transparent",
            '&:hover': {
                backgroundColor:"var(--color1)",
                color: "var(--weiss)",
            },
            borderRadius: 0,
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            margin: currentStep < availableSteps.length ? "0 0 0 auto !important" : "0 auto !important",
            textTransform: "inherit",
            border: "3px solid var(--color1)"
        },
    }))(Button);
    const ColorButtonToTop = withStyles((theme) => ({
        root: {
            color: "var(--color2)",
            backgroundColor: "transparent",
            cursor: "pointer",
            borderRadius: 0,
            width: "auto",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "-40px -5px 0 auto",
            textTransform: "inherit",
            border: "3px solid transparent",
            '&:hover': {
                backgroundColor: "transparent",
                color: "var(--color1)",
            },
            float: "right",
        },
    }))(Button);
    const ColorButton2 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--color1)",
            '&:hover': {
                backgroundColor:"var(--color1)",
            },
            borderRadius: 0,
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            margin: currentStep > 1 ? "0 auto 0 15px !important" : "0 auto !important",
            textTransform: "inherit"
        },
    }))(Button);
    const ColorButton3 = withStyles((theme) => ({
        root: {
            color: "rgba(0, 0, 0, 0.26)",
            backgroundColor: "var(--color1)",
            '&:hover': {
                backgroundColor:"var(--color1)",
            },
            borderRadius: 0,
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontWeight: "bold",
            fontSize: "16px",
            textAlign: "center",
            margin: "20px",
            textTransform: "inherit"
        },
    }))(Button);
    function ArrowRight(a, b, c) {
        if (a.completed || a.active) { return <img src={arrowRight} height="32" alt="arrow-right" style={{margin: '0 auto'}} />; }
        if (!a.active && !a.completed) { return <img src={arrowRightGray} height="20" alt="arrow-right" style={{margin: '0 auto'}} />;}
        console.log('a', a, 'b', b, 'c', c)

        return <img src={arrowRight} height="32" alt="arrow-right" />;
    }
    const theme = createTheme({
        overrides: {
            MuiStepper:{
                root:{
                    padding: "10px",
                    background: "transparent",
                    marginTop: "20px",
                }
            },
            MuiStepIcon: {
                root:{
                    fontSize: "14px",
                    color: 'var(--textColor)',
                    "&$active": {
                        color: 'var(--blue)'
                    },
                    "&$completed": {
                        color: 'var(--blue)'
                    }
                },
                text: {
                    fill: "currentcolor"
                }
            },
            MuiStepLabel:{
                label: {
                    color: 'var(--textColor)',
                    "&$active": {
                        color: 'var(--blue)',
                    },
                    "&$completed": {
                        color: 'var(--blue)',
                    }
                }
            }
        },
    });
    function navigateToTop() {
        saveIdTracking('btn_nav', 'btn_jump_to_top');
        window.scrollTo(0, 0);
    }

    function handleResetDialogOpen() {
        setResetDialogOpen(true);
    }
    function handleResetFAQDialogOpen() {
        saveIdTracking('btn_nav', 'btn_open_faq');
        setResetFAQDialogOpen(true);
    }
    function handleResetDialogClose(){
        setResetDialogOpen(false);
    }
    function handleResetFAQDialogClose(){
        setExpanded('panel1');
        setResetFAQDialogOpen(false);
    }
    function handleResetDialogAgree(e){
        saveIdTracking('btn_nav', 'btn_new_project')
        setTrackingId(null);
        ProjectSaveService.saveTracking({trackingId: null, step: 1, b2b: isExpert ? true : false}, setTrackingId);
        setProject(initProject());
        setCurrentStep(1);
        setUiState(getInitialUiState());
        setResetDialogOpen(false);
        window.scrollTo(0, 0);
    }

    function getNextButton(){
        let max = project.floors.length;
        for(let i = 0; i < max; i++){
            if(project.floors[i].rooms.length > 0){
                return <ColorButton2 id={"btn_next_step"} className={"btn_project_nav"} onClick={e => navigateToStep(currentStep + 1, true)}>weiter</ColorButton2>;
            }
        }
        return <>
            <div style={{textAlign: "center"}}>Sie müssen mindestens eine Etage und Raum anlegen</div>
            <ColorButton3>weiter</ColorButton3>
        </>;
    }

    function CustomStepIcon(props) {
        const { active, completed, icon} = props;

        return (
            <div className="customIcon">
                {completed ? (
                    <svg className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-completed" focusable="false" viewBox="0 0 28 28"
                         aria-hidden="true">
                        <circle cx="14" cy="14" r="14"></circle>
                        <text className="MuiStepIcon-text" x="14" y="19" textAnchor="middle">{icon}</text>
                    </svg>
                ) : (active ? (
                        <svg className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active" focusable="false" viewBox="0 0 28 28"
                             aria-hidden="true">
                            <circle cx="14" cy="14" r="14"></circle>
                            <text className="MuiStepIcon-text" x="14" y="19" textAnchor="middle">{icon}</text>
                        </svg>
                    ) : (
                    <svg className="MuiSvgIcon-root MuiStepIcon-root" focusable="false" viewBox="0 0 28 28"
                         aria-hidden="true">
                        <circle cx="14" cy="14" r="14"></circle>
                        <text className={"MuiStepIcon-text"} x="14" y="19" textAnchor="middle" style={{color: 'gray'}}>{icon}</text>
                    </svg>
                    )
                )}
            </div>
        );
    }

    const Accordion = withStyles({
        root: {
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles({
        root: {
            backgroundColor: 'rgba(0, 0, 0, .003)',
            borderBottom: 0,
            marginBottom: -1,
            minHeight: 56,
            fontWeight: "bold",
            '&$expanded': {
                minHeight: 56,
            },
            color: "var(--color2)"
        },
        content: {
            '&$expanded': {
                margin: '12px 0',
            },
        },
        expanded: {},
    })(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
            color: "var(--color2)",
            fontWeight: "normal"
        },
    }))(MuiAccordionDetails);
    function AccExpandIconOpened() {
        return <svg viewBox="0 0 204.5 37.15" width="12" height="22" fill="#1a477a" style={{marginLeft: "5px", marginTop: "-5px"}}>
            <polyline points="76.73 37.15 204.5 37.15 204.5 0 76.73 0"/>
            <polyline points="82.38 0 0 0 0 37.15 82.38 37.15"/>
        </svg>;
    }
    function AccExpandIconClosed() {
        return <svg viewBox="0 0 204.5 37.15" width="12" height="22" fill="#1a477a" style={{marginRight: "5px", marginTop: "-5px"}}>
            <polygon points="82.38 0 82.38 88.27 0 88.27 0 125.42 82.38 125.42 82.38 221.19 122.73 221.19 122.73 125.42 204.5 125.42 204.5 88.27 122.73 88.27 122.73 0 82.38 0"/>
        </svg>;
    }
    function ButtonCloseFAQ() {
        return <Button className={"btn-close-faq"} onClick={e =>handleResetFAQDialogClose()}>
            <svg viewBox="0 0 1024 960" width="24" height="24">
                <path d="M651.938 450.076l316.834 316.834c10.59 12.751 17.016 29.29 17.016 47.33 0 41.051-33.278 74.329-74.329 74.329-18.040 0-34.578-6.427-47.452-17.115l0.122 0.099-317.249-316.834-317.249 316.834c-13.653 16.325-34.030 26.635-56.815 26.635-18.481 0-35.377-6.782-48.336-17.994l0.093 0.079c-15.87-13.782-25.847-33.992-25.847-56.531 0-18.377 6.632-35.205 17.633-48.221l-0.091 0.111 8.305-8.72 317.249-316.834-317.249-317.249c-10.59-12.751-17.016-29.29-17.016-47.33 0-41.051 33.278-74.329 74.329-74.329 18.040 0 34.578 6.427 47.452 17.115l-0.122-0.099 317.249 317.249 317.249-317.249c13.612-14.625 32.972-23.745 54.461-23.745 41.051 0 74.329 33.278 74.329 74.329 0 17.144-5.804 32.932-15.554 45.507l0.126-0.169c-2.73 3.14-5.58 5.99-8.615 8.631l-0.105 0.089z" />
            </svg>
        </Button>;
    }
    function loadProjectFromCode(){
        if(projectCode){
            // setLoadProjectOpen(false);
            setTrackingId(null);
            ProjectSaveService.saveTracking({trackingId: null, step: 1, b2b: isExpert ? true : false}, setTrackingId);
            setTimeout(() => {
                saveIdTracking('btn_cta', 'btn_submit_project_code');
                ProjectSaveService.loadProject(projectCode, startWithProject);
            }, 500);
        }
    }
    function LoadProjectButton() {
        return <div className={"section-container"} style={{paddingTop: '0'}}>
            <div className={"section-title"}>Bestehendes Projekt:</div>
            <div className="inputBoxContainer" style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "10px"}}>
                <input type="text" name="projectCode" className="inputText"
                       onChange={e => setProjectCode(e.target.value?.toUpperCase())} value={projectCode}
                       placeholder={"Planungscode eingeben"}
                       style={{padding: "15px", border: "1px solid var(--grau5)", flexGrow: "1", marginRight: "15px", fontSize: "16px"}}
                />
                <ColorButton2 id={"btn_submit_project_code"} className={"btn_cta"} style={{margin: "0 0 0 auto", display: "flex"}} onClick={e => {loadProjectFromCode()}}>Starten</ColorButton2>
            </div>
        </div>;
    }
    function saveIdTrackingA(e, id, value, url) {
        e.preventDefault();
        saveIdTracking(id, value);
        window.open(url, '_blank');
        return true;
    }
    return <MuiThemeProvider theme={theme}>
        <div className={"header-container"}>
            <div className={"logo-container"}>
                <img src={logoSvg} alt="logo"/>
                <div className={"links-container"}>
                    {/*<span id={"btn_open_faq"} className={"headerMenuItem btn_nav"} onClick={handleResetFAQDialogOpen} title={"FAQ - Häufig gestellte Fragen"}>FAQ</span>*/}
                    <span id={"btn_new_project"} className={"headerMenuItem btn_nav"} onClick={handleResetDialogOpen} title={"Neue Planung beginnen"}>Neue Planung</span>
                    <Dialog
                        open={resetDialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleResetDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" color={"inherit"}>
                                Wollen Sie wirklich eine neue Planung?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent: "center"}}>
                            <div onClick={handleResetDialogClose} color="primary"
                                 className={"modalButton disagree"} style={{fontWeight: "bold"}}>
                                Abbrechen
                            </div>
                            <div onClick={handleResetDialogAgree} color="primary" className={"modalButton agree"} style={{fontWeight: "bold"}}>
                                Neue Planung
                            </div>
                        </DialogActions>
                    </Dialog>
                    {/*<Dialog*/}
                    {/*    open={resetFAQDialogOpen}*/}
                    {/*    TransitionComponent={Transition}*/}
                    {/*    keepMounted*/}
                    {/*    onClose={handleResetFAQDialogClose}*/}
                    {/*    aria-labelledby="alert-dialog-slide-title"*/}
                    {/*    aria-describedby="alert-dialog-slide-description"*/}
                    {/*    // onBackdropClick={handleResetFAQDialogClose}*/}
                    {/*    maxWidth="lg"*/}
                    {/*>*/}
                    {/*    <DialogContent id="alert-dialog-slide-description" color={"inherit"}>*/}
                    {/*        <div className={"faq-title header-item-new-up"}>Häufig gestellte Fragen <ButtonCloseFAQ /></div>*/}
                    {/*        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>*/}
                    {/*            <AccordionSummary*/}
                    {/*                expandIcon={expanded === 'panel1' ? <AccExpandIconOpened /> : <AccExpandIconClosed />}*/}
                    {/*                aria-controls="panel1a-content"*/}
                    {/*                id="panel1a-header"*/}
                    {/*            >*/}
                    {/*                <Typography style={{fontWeight: 'bold'}}>Blindcopy fgrage an diese Stele tzende in rerende se meo?</Typography>*/}
                    {/*            </AccordionSummary>*/}
                    {/*            <AccordionDetails>*/}
                    {/*                <Typography>*/}
                    {/*                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.*/}
                    {/*                </Typography>*/}
                    {/*            </AccordionDetails>*/}
                    {/*        </Accordion>*/}
                    {/*        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>*/}
                    {/*            <AccordionSummary*/}
                    {/*                expandIcon={expanded === 'panel2' ? <AccExpandIconOpened /> : <AccExpandIconClosed />}*/}
                    {/*                aria-controls="panel2a-content"*/}
                    {/*                id="panel2a-header"*/}
                    {/*            >*/}
                    {/*                <Typography style={{fontWeight: 'bold'}}>Blindcopy fgrage an diese Stele tzende in rerende se meo?</Typography>*/}
                    {/*            </AccordionSummary>*/}
                    {/*            <AccordionDetails>*/}
                    {/*                <Typography>*/}
                    {/*                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse*/}
                    {/*                    malesuada lacus ex, sit amet blandit leo lobortis eget.*/}
                    {/*                </Typography>*/}
                    {/*            </AccordionDetails>*/}
                    {/*        </Accordion>*/}
                    {/*        <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>*/}
                    {/*                <AccordionSummary*/}
                    {/*                    expandIcon={expanded === 'panel3' ? <AccExpandIconOpened /> : <AccExpandIconClosed />}*/}
                    {/*                    aria-controls="panel2a-content"*/}
                    {/*                    id="panel2a-header"*/}
                    {/*                >*/}
                    {/*                    <Typography style={{fontWeight: 'bold'}}>Blindcopy fgrage an diese Stele tzende in rerende se meo?</Typography>*/}
                    {/*                </AccordionSummary>*/}
                    {/*                <AccordionDetails>*/}
                    {/*                    <Typography>*/}
                    {/*                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse*/}
                    {/*                        malesuada lacus ex, sit amet blandit leo lobortis eget.*/}
                    {/*                    </Typography>*/}
                    {/*                </AccordionDetails>*/}
                    {/*            </Accordion>*/}
                    {/*    </DialogContent>*/}
                    {/*</Dialog>*/}
                </div>
            </div>
            <div className={"banner-container"}>
                <img src={bannerSvg} alt="banner"/>
                <div className={"welcome-container"}>
                    <div className={"title darkblue"}>Elektroinstallation planen</div>
                    <div className={"title"}>mit easyplan</div>
                    <div className={"text"}>Konfigurieren Sie schnell und einfach die elektrotechnische Ausstattung für Ihr Zuhause.</div>
                </div>
            </div>
        </div>
        <div className={"project-container"}>
            <div className={"stepper-container"}>
                <Stepper activeStep={currentStep - 1} alternativeLabel connector={<ArrowRight />}>
                    <Step key="1" id={"btn_step_1"}
                          onClick={e => currentStep > 1 ? navigateToStep(1) : null}
                          className={"btn_project_nav " + (currentStep > 1 ? "mouseover-hand" : "")}
                    >
                        <StepLabel StepIconComponent={CustomStepIcon}>Gebäudestruktur</StepLabel>
                    </Step>
                    <Step key="2" id={"btn_step_2"}
                          onClick={e => currentStep > 2 ? navigateToStep(2) : (currentStep === 1 ? navigateToStep(2) : null)}
                          className={[1, 3, 4, 5, 6].includes(currentStep) ? "mouseover-hand" : ""}
                    >
                        <StepLabel StepIconComponent={CustomStepIcon}>Ausstattung</StepLabel>
                    </Step>
                    <Step key="3" id={"btn_step_3"}
                          onClick={e => currentStep > 3 ? navigateToStep(3) : (currentStep === 2 ? navigateToStep(3) : null)}
                          className={"btn_project_nav " + ([2, 4, 5, 6].includes(currentStep) ? "mouseover-hand" : "")}
                    >
                        <StepLabel StepIconComponent={CustomStepIcon}>Schalterdesign</StepLabel>
                    </Step>
                    <Step key="4" id={"btn_step_4"}
                          onClick={e => currentStep > 4 ? navigateToStep(4) : (currentStep === 3 ? navigateToStep(4) : null)}
                          className={"btn_project_nav " + ([3, 5, 6].includes(currentStep) ? "mouseover-hand" : "")}
                    >
                        <StepLabel StepIconComponent={CustomStepIcon}>Optionen</StepLabel>
                    </Step>
                    {isExpert ?
                        <Step key="5" id={"btn_step_5"}
                              onClick={e => currentStep > 5 ? navigateToStep(5) : (currentStep === 4 ? navigateToStep(5) : null)}
                              className={"btn_project_nav " + ([4, 6].includes(currentStep) ? "mouseover-hand" : "")}
                        >
                            <StepLabel StepIconComponent={CustomStepIcon}>Produktvarianten</StepLabel>
                        </Step> : null}
                    {isExpert ?
                        <Step key="6" id={"btn_step_6"}
                              onClick={e => currentStep === 5 ? navigateToStep(6) : null}
                              className={"btn_project_nav " + (currentStep === 5 ? "mouseover-hand" : "")}
                        >
                            <StepLabel StepIconComponent={CustomStepIcon}>Zusammenfassung</StepLabel>
                        </Step> :
                        <Step key="6" id={"btn_step_6"}
                              onClick={e => currentStep === 4 ? navigateToStep(5) : null}
                              className={"btn_project_nav " + (currentStep === 4 ? "mouseover-hand" : "")}
                        >
                            <StepLabel StepIconComponent={CustomStepIcon}>Zusammenfassung</StepLabel>
                        </Step>
                    }
                </Stepper>
            </div>
            {currentStep === 1 ? LoadProjectButton() : null}
            {renderSwitch(currentStep)}
        </div>
        <div className={"footer-container"}>
            <div className={"footer-actions-container"}>
                {currentStep > 1 ?
                    <ColorButton1 id={"btn_previous_step"} className={"btn_project_nav"} onClick={e => navigateToStep(currentStep - 1)}>zurück</ColorButton1> : null}
                {currentStep < availableSteps.length ? getNextButton() : null}
                {currentStep <= availableSteps.length ?
                    <ColorButtonToTop onClick={e => navigateToTop()} className="button woText btn_nav" id={"btn_jump_to_top"}>
                        <img className="icon-to-page-bottom" src={arrowUpSvg} alt={""}/>
                        <img className="icon-to-page-bottom only-hover" src={arrowUpSvg} alt={""} style={{opacity: "0.7"}}/>
                    </ColorButtonToTop> : null}
            </div>
            <div className={"footer fs-box-30 clearfix no-border"}>
                <div style={{float: "left"}}>
                    © Hager by FEELSMART.
                </div>
                <div className={"footerMenu"} style={{float: "right"}}>
                    <a id={'btn_open_imprint'} className={"btn_nav"} href={"#"} target={"_blank"} rel="noreferrer" onClick={(e) => saveIdTrackingA(e, 'btn_nav', 'btn_open_imprint', 'https://feelsmart.de/impressum')}>Impressum </a>
                    <a id={'btn_open_privacy_declaration'} className={"btn_nav"} href={"#"} target={"_blank"} rel="noreferrer" onClick={(e) => saveIdTrackingA(e, 'btn_nav', 'btn_open_privacy_declaration', 'https://feelsmart.de/datenschutz')}>Datenschutz</a>
                    <a id={'btn_open_terms'} className={"btn_nav"} href={"#"} target={"_blank"} rel="noreferrer" onClick={(e) => saveIdTrackingA(e, 'btn_nav', 'btn_open_terms', 'https://feelsmart.de/nutzungsbedingungen')}>Nutzungsbedingungen</a>
                </div>
            </div>
        </div>
    </MuiThemeProvider>

}

export class ProjectSaveService{

    static version = "0.1";

    static getDomain(){
        return process.env.REACT_APP_QUICK_BACKEND_URL || 'https://fs-conf3-hager-dev.p14.de';
    }

    static saveProject(project, projectPro, setPlanungsCode){
        let obj = {};
        Object.keys(project.selectedEquipment).forEach(key => {
            obj = {...obj, [key]: project.selectedEquipment[key]};
        });
        project = {...project, selectedEquipment: obj};
        // if (!projectPro) { projectPro = actionsList.generatedProject; }
        fetch(
            this.getDomain() + "/configurator-api/quickplaner/save",
            {
                method: "post",
                body: JSON.stringify({version: this.version, dataP: projectPro, dataQ: project})
            }
        ).then(res => res.json()).then(result => {
            setPlanungsCode(result.code)
        }).catch(console.log);
    }

    static loadProject(code, startWithProject){
        fetch(
            this.getDomain() + "/configurator-api/quickplaner/load/" + code,
            {
                method: "get"
            }
        ).then(res => res.json()).then(result => {
            if (result.dataQ.isExpert !== undefined) {
                if (result.dataQ.isExpert) {
                    if (window.location.toString().indexOf('/customer') >= 0) {
                        window.location.replace("/expert/" + code);
                    } else {
                        startWithProject({...result.dataQ, loadedFromCode: true});
                    }
                } else {
                    if (window.location.toString().indexOf('/expert') >= 0) {
                        window.location.replace("/customer/" + code);
                    } else {
                        startWithProject({...result.dataQ, loadedFromCode: true});
                    }
                }
            } else {
                startWithProject({...result.dataQ, loadedFromCode: true})
            }
        }).catch(e => alert("Code not supported!"));
    }

    static saveTracking(params, setTrackingId, trackingObj = null) {
        if (params.projectType !== undefined) {
            let projectTypes = {
                knx: "KNX",
                easy: "HAGER_KNX_EASY",
                konventionell: "CONVENTIONAL"
            };
            switch (params.projectType) {
                case "knx":
                case "easy":
                case "konventionell":
                    params = {...params, projectType: projectTypes[params.projectType]};
                    break;
                default: break;
            }
        }
        fetch(
            this.getDomain() + '/configurator-api/quickplaner/tracking',
            {
                method: 'post',
                mode: "cors",
                body: JSON.stringify(params)
            }
        ).then(res => res.json())
            .then(result => {
                if (trackingObj) { Object.assign(trackingObj, params, {trackingId: result.trackingId}); }
                else { trackingObj = Object.assign(params, {trackingId: result.trackingId}); }
                if (typeof setTrackingId === "function") { setTrackingId(trackingObj); }
            }).catch(e => console.log('tracking post error', e));
    }
}

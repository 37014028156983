import {v4 as uuidv4} from "uuid";

export class GenerateRoomToAddService{

    static defaultDesignConfig = undefined;

    static resetDefaultDesignConfig(){
        GenerateRoomToAddService.defaultDesignConfig = undefined;
    }

    static getDefaultDesignConfig(schalterdesign){
        if(GenerateRoomToAddService.defaultDesignConfig === undefined){

            var serie = schalterdesign.filter(s => s.isDefault === true)[0];
            serie = (serie !== undefined) ? serie : schalterdesign[0];
            var color1 =  serie.colorsTaster.filter(t => t.isDefault === true)[0];
            color1 = (color1 !== undefined) ? color1 : serie.colorsTaster[0];
            var color2 =  color1.colorsRahmen.filter(t => t.isDefault === true)[0];
            color2 = (color2 !== undefined) ? color2 : color1.colorsRahmen[0];

            GenerateRoomToAddService.defaultDesignConfig = {
                selectedSerie: serie,
                selectedColor1: color1,
                selectedColor2: color2
            };
        }
        return GenerateRoomToAddService.defaultDesignConfig;
    }

    static generate({roomIn, selectedLevel, schalterdesign}) {
        const levelDataList = roomIn.defaultEquipment.filter(de => (de.level === selectedLevel));

        const levelData = levelDataList.length > 0 ? levelDataList[0] : {};

        var roomOut = {
            id: uuidv4(),
            type: roomIn.type,
            name: roomIn.name,
            doorCount: roomIn.doorCount,
            windowCount: roomIn.windowCount,
            heatingCount: roomIn.heatingCount,
            floorheatingCount: roomIn.floorheatingCount,
            selectedEquipment: levelData.values ? {...levelData.values} : {},
            selectedDesign: roomIn.selectedDesign ? roomIn.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign)
        };
        return roomOut;
    }
}

import React from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RoomComponent from "./RoomComponent";
import AddRoomComponent from "./AddRoomComponent";
import {ReactSortable} from "react-sortablejs";
import LabelBox from "./LabelBox/LabelBox";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import CountBox from "./CountBox/CountBox";
import AddFloorComponent from "./AddFloorComponent";

export default function FloorComponent({project, floor, updateFloor, deleteFloor, schalterdesign, opened, withCounter = false, isLast = false, onAddFloor, onFloorClose, saveIdTracking}){

    const [open, setOpen] = React.useState(opened !== undefined ? opened : true);
    let floorValues = { doorCount: 0, windowCount: 0, heatingCount: 0, floorheatingCount: 0};

    function onOpenClose(){
        // on close notify parent to show the add floor button
        onFloorClose(open);
        setOpen(!open);
    }
    if (isLast) { onFloorClose(!open); }
    function rename(name){
        updateFloor({...floor, name: name});
    }
    function updateRoom(room){
        updateFloor({...floor, rooms: floor.rooms.map(r => r.id !== room.id ? r : room), floorValues: floorValues});
    }
    function addRoom(room){
        saveIdTracking('btn_building_structure_cta', 'building_structure_add_room');
        updateFloor({
            ...floor,
            rooms: [
                ...floor.rooms,
                GenerateRoomToAddService.generate({roomIn: room,selectedLevel: project.selectedLevel, schalterdesign: schalterdesign})]});
    }
    function setRooms(rooms){
        updateFloor({...floor, rooms: rooms});
    }
    function deleteRoom(room){
        updateFloor({...floor, rooms: floor.rooms.filter(r => r.id !== room.id)});
    }
    function onDelete(e){
        deleteFloor(floor);
    }

    const [dragActive, setDragActive] = React.useState(false);
    function onStart(){
        setDragActive(true)
    }
    function onEnd(){
        setDragActive(false)
    }
    function setValueForRooms(floor, cat, v) {
        floorValues[cat] = v;
        floor.rooms.map(r => {
            r[cat] = v;
            updateRoom(r);
            return r;
        });
    }
    if (floor.floorValues) {
        floorValues = Object.assign(floorValues, floor.floorValues);
    }

    return <div className="floorWrapper">
        <div className="floorBoxHeader" style={{display: withCounter ? 'flex' : null, alignItems: withCounter ? 'center' : null, position: "relative"}}>
            <div className="openClose" onClick={onOpenClose} style={{position: "absolute", left: "-40px"}}>
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
            </div>
            <div className={"col-3"} style={{position: "relative"}}>
                <LabelBox value={floor.name} setValue={rename} deleteItem={onDelete} isBold={true}/>
            </div>
            {withCounter ? <div className={"text-center col-1 float-right"}>Alle</div> : null}
            {withCounter ? <div className={"text-center col-2 float-right"}><CountBox minValue={0} maxValue={99} value={floorValues.doorCount} setValue={value => setValueForRooms(floor, 'doorCount', value)}/></div> : null}
            {withCounter ? <div className={"text-center col-2 float-right"}><CountBox minValue={0} maxValue={99} value={floorValues.windowCount} setValue={value => setValueForRooms(floor, 'windowCount', value)}/></div> : null}
            {withCounter ? <div className={"text-center col-2 float-right"}><CountBox minValue={0} maxValue={99} value={floorValues.heatingCount} setValue={value => setValueForRooms(floor, 'heatingCount', value)}/></div> : null}
            {withCounter ? <div className={"text-center col-2 float-right"}><CountBox minValue={0} maxValue={99} value={floorValues.floorheatingCount} setValue={value => setValueForRooms(floor, 'floorheatingCount', value)}/></div> : null}
        </div>
        {open ? (
            <div className={"roomList" + (dragActive ? " dragActive" : "")} >
                <ReactSortable
                    list={floor.rooms}
                    setList={l => setRooms(l, floor)}
                    group={"roomLists"}
                    handle={".dragHandle"}
                    onStart={onStart}
                    onEnd={onEnd}
                >
                    {floor.rooms.map(
                        r => <RoomComponent room={r} key={r.id} updateRoom={updateRoom} deleteRoom={deleteRoom}/>
                    )}
                </ReactSortable>
                {isLast
                    ? <div className={"actions-container"}>
                        <AddRoomComponent onAddRoom={addRoom} floor={floor}/>
                        <AddFloorComponent onAddFloor={onAddFloor} />
                    </div>
                    : <AddRoomComponent onAddRoom={addRoom} floor={floor}/>
                }
            </div>
        ) : null }
    </div>
}


export default function CountActuatorsService(listIn, projectIn, ausstattung_produkt) {
    const listOut = [];
    const removeList = [];

    const heating = [
        {key: "heizen-smart-aktor", count: 6},
        {key: "rt-aktor", count: 6}
    ];

    heating.forEach(heatingAktor => {
        const found = listIn.filter(i => i.product === ausstattung_produkt[heatingAktor.key]);
        if (found && found.length) {
            let needed = 0;
            found.forEach(item => needed += item.count);
            // const needed = Math.floor(found.length / heatingAktor.count);
            const remaining = 0;// found.length % heatingAktor.count;
            removeList.push(ausstattung_produkt[heatingAktor.key]);
            listOut.push({product: ausstattung_produkt[heatingAktor.key], count: needed + (remaining > 0 ? 1 : 0)});
        }
    });

    return {
        list: listOut,
        removeList: removeList
    };
}

import myKonventionellSwitchList from "../../data/konventionell_switch_product_list";
import myKonventionellTurndimList from "../../data/konventionell_turndim_product_list";
import myKonventionellPushdimList from "../../data/konventionell_pushdim_product_list";


export default function getProducts(room, austattung_produkt, selectedAlternatives){

    const productList = [];

    let countFirst = 0;
    let countAll = 0;
    let countSwitch = 0;
    let countTurndim = 0;
    let countPushdim = 0;
    let countShading = 0;
    let countSteckdosen = 0;

    const checkList = [
        {
            key: "schaltbare_beleuchtung",
            onlyFirst: false
        },
        {
            key: "dimmbare_beleuchtung_dreh",
            onlyFirst: false
        },
        {
            key: "dimmbare_beleuchtung_tast",
            onlyFirst: false
        },
        {
            key: "schaltbare_steckdosen",
            onlyFirst: false
        },
        {
            key: "rolllaeden_steuern",
            onlyFirst: true
        },
        {
            key: "jalousie_steuern",
            onlyFirst: true
        }
    ];
// console.log('room selectedequipment', room.selectedEquipment)
    checkList.forEach(item => {
        if(
            room.selectedEquipment[item.key] !== undefined
            && room.selectedEquipment[item.key] > 0
        ){
            if(item.key === "schaltbare_beleuchtung"){
                countSwitch += room.selectedEquipment[item.key];
            }else if(item.key === "dimmbare_beleuchtung_dreh"){
                countTurndim += room.selectedEquipment[item.key];
            }else if(item.key === "dimmbare_beleuchtung_tast"){
                countPushdim += room.selectedEquipment[item.key];
            }else if(item.key === "rolllaeden_steuern" || item.key === "jalousie_steuern"){
                countShading += room.selectedEquipment[item.key];
            }else if(item.key === 'schaltbare_steckdosen'){
                countSteckdosen += room.selectedEquipment[item.key];
            }
//            if(!item.onlyFirst) {
//                countAll += room.selectedEquipment[item.key];
//            }
//            countFirst += room.selectedEquipment[item.key];
        }
    });

    let countForFrameFirst = 0;
    let countForFrameAll = 0;
    let countForFrameFirst_t4 = 0;
    let countForFrameAll_t4 = 0;
// console.log('room', room,
//     {countTurndim: countTurndim, countPushdim: countPushdim, countSwitch: countSwitch, countShading: countShading, countSteckdosen: countSteckdosen},
//     'austattung', austattung_produkt)
    countAll += getSwitchProducts(room, countSwitch, austattung_produkt, productList, myKonventionellSwitchList, countForFrameAll);
// console.log('productlist switch', productList)
    countAll += getTurndimProducts(room, countTurndim, austattung_produkt, productList, myKonventionellTurndimList, countForFrameAll);
// console.log('productlist turndim', productList)
    countAll += getPushdimProducts(room, countPushdim, austattung_produkt, productList, myKonventionellPushdimList, countForFrameAll);
// console.log('productlist pushdim', productList)
    countAll += getSteckdosenProducts(room, countSteckdosen, austattung_produkt, productList, myKonventionellSwitchList, countForFrameAll);
// console.log('productlist steckdosen', productList)
    getShadingProducts(countShading, austattung_produkt, productList);
// console.log('productlist shading', productList, countShading)

    if(countFirst > 0){
        [
            {factor: 4, key: "t4"},
            {factor: 3, key: "t3"},
            {factor: 2, key: "t2", alt: {key: "t2a", newKeys: ["t2-ba", "t2-ba-wippe"]}},
            {factor: 1, key: "t1", alt: {key: "t1a", newKeys: ["t1-ba", "t1-ba-wippe"]}}
        ].forEach(taster => {
            if(
                countFirst / taster.factor >= 1
                && austattung_produkt[taster.key] !== undefined
            ){
                const c = Math.ceil(countFirst / taster.factor);
                let altList = undefined;
                if(taster.alt !== undefined){
                    altList = [
                        {
                            key: taster.key,
                            value: undefined,
                            products: [austattung_produkt[taster.key], austattung_produkt["busanakoppler"]]
                        },
                        {
                            key: taster.key,
                            value: taster.alt.key,
                            products: taster.alt.newKeys.map(nk => austattung_produkt[nk])
                        }
                    ];
                }
                if(
                    selectedAlternatives !== undefined
                    && selectedAlternatives[taster.key] !== undefined
                ){
                    productList.push({
                        product: austattung_produkt[taster.alt.newKeys[0]],
                        count: c,
                        alt: altList
                    });
                    productList.push({
                        product: austattung_produkt[taster.alt.newKeys[1]],
                        count: c
                    })
                }else{
                    productList.push({
                        product: austattung_produkt[taster.key],
                        count: c,
                        alt: altList
                    });
                    productList.push({
                        product: austattung_produkt["busanakoppler"],
                        count: c
                    })
                }
                if(taster.key === "t4" && (
                    room.selectedDesign.selectedSerie.id === "s-1-e" || room.selectedDesign.selectedSerie.id === "b-3-e" ||
                    room.selectedDesign.selectedSerie.id === "b-7-e" || room.selectedDesign.selectedSerie.id === "k-1-e" ||
                    room.selectedDesign.selectedSerie.id === "k-5-e")){
                    countForFrameFirst_t4 += c;
                }else {
                    countForFrameFirst += c;
                }
                countFirst -= c * taster.factor;
            }
        });
    }
    if(countAll > 0){
        [
            {factor: 4, key: "t4"},
            {factor: 3, key: "t3"},
            {factor: 2, key: "t2", alt: {key: "t2a", newKeys: ["t2-ba", "t2-ba-wippe"]}},
            {factor: 1, key: "t1", alt: {key: "t1a", newKeys: ["t1-ba", "t1-ba-wippe"]}}
        ].forEach(taster => {
            if(
                (room.doorCount - 1) > 0
                && countAll / taster.factor >= 1
                && austattung_produkt[taster.key] !== undefined
            ){
                const c = Math.ceil(countAll / taster.factor);
                let altList = undefined;
                if(taster.alt !== undefined){
                    altList = [
                        {
                            key: taster.key,
                            value: undefined,
                            products: [austattung_produkt[taster.key], austattung_produkt["busanakoppler"]]
                        },
                        {
                            key: taster.key,
                            value: taster.alt.key,
                            products: taster.alt.newKeys.map(nk => austattung_produkt[nk])
                        }
                    ];
                }
                if(
                    selectedAlternatives !== undefined
                    && selectedAlternatives[taster.key] !== undefined
                ){
                    productList.push({
                        product: austattung_produkt[taster.alt.newKeys[0]],
                        count: c* (room.doorCount - 1),
                        alt: altList
                    });
                    productList.push({
                        product: austattung_produkt[taster.alt.newKeys[1]],
                        count: c* (room.doorCount - 1)
                    })
                }else{
                    productList.push({
                        product: austattung_produkt[taster.key],
                        count: c* (room.doorCount - 1),
                        alt: altList
                    });
                    productList.push({
                        product: austattung_produkt["busanakoppler"],
                        count: c* (room.doorCount - 1)
                    })
                }
                if(taster.key === "t4" && (
                    room.selectedDesign.selectedSerie.id === "s-1-e" || room.selectedDesign.selectedSerie.id === "b-3-e" ||
                    room.selectedDesign.selectedSerie.id === "b-7-e" || room.selectedDesign.selectedSerie.id === "k-1-e" ||
                    room.selectedDesign.selectedSerie.id === "k-5-e")){
                    countForFrameAll_t4 += c;
                }else {
                    countForFrameAll += c;
                }
                countAll -= c * taster.factor;
            }
        });
    }
    [{count: countForFrameFirst, factor: 1}, {count: countForFrameAll, factor: (room.doorCount -1)}].forEach(countObj => {
        let innerCount = countObj.count;
        [
            {factor: 5, key: "r-5f"},
            {factor: 4, key: "r-4f"},
            {factor: 3, key: "r-3f"},
            {factor: 2, key: "r-2f"},
            {factor: 1, key: "r-1f"}
        ].forEach(frame => {
            if(
                innerCount / frame.factor >= 1
                && austattung_produkt[frame.key] !== undefined
            ){
                const c = Math.ceil(innerCount / frame.factor);
                productList.push({
                    product: austattung_produkt[frame.key],
                    count: c * countObj.factor
                });
                innerCount -= c * frame.factor;
            }
        });
    });

    if(countForFrameFirst_t4 > 0){
        productList.push({
            product: austattung_produkt["s-r1f"],
            count: countForFrameFirst_t4
        })
    }
    if(countForFrameAll_t4 > 0){
        productList.push({
            product: austattung_produkt["s-r1f"],
            count: countForFrameFirst_t4 * (room.doorCount -1)
        })
    }


    return productList;
}



function getSteckdosenProducts(room, countSteckdosen, austattung_produkt, productList, myList, countProducts){
    const key = room.doorCount + "_" + countSteckdosen;
    if (myList[key] !== undefined){
        myList[key].forEach(item => {
            if(austattung_produkt[item.product] !== undefined){
                productList.push({
                    product: austattung_produkt[item.product],
                    count: item.count
                });
            }
            countProducts += item.count;
        });
    }
    return countProducts;
}
function getSwitchProducts(room, countSwitch, austattung_produkt, productList, myList, countProducts){

    const key = room.doorCount + "_" + countSwitch;
// console.log('getSwitchProducts', room, countSwitch, austattung_produkt, myList, key)
    if (myList[key] !== undefined){
        myList[key].forEach(item => {
            if(austattung_produkt[item.product] !== undefined){
                productList.push({
                    product: austattung_produkt[item.product],
                    count: item.count
                });
                // console.log('pushed switch product', item.product, austattung_produkt[item.product], item.count)
            } else {
                // console.log('CRITICAL: product not found', item.product, austattung_produkt)
            }
            countProducts += item.count;
        });
    }
    else {
        // console.log('CRITICAL: key not found in myList', key, myList)
    }
    return countProducts;
}

function getTurndimProducts(room, countTurndim, austattung_produkt, productList, myList, countProducts){

    const key = room.doorCount + "_" + countTurndim;
// console.log('turnDim key', key, myList[key])
    if (myList[key] !== undefined){
        myList[key].forEach(item => {
            // console.log('parsing item', item)
            if(austattung_produkt[item.product] !== undefined){
                productList.push({
                    product: austattung_produkt[item.product],
                    count: item.count
                });
                // console.log('pushed', item.product, austattung_produkt[item.product], item.count)
            } else {
                // console.log('product not in austattung', item.product, austattung_produkt)
            }
            countProducts += item.count;
        });
    }
    return countProducts;
}

function getPushdimProducts(room, countPushdim, austattung_produkt, productList, myList, countProducts){

    const key = room.doorCount + "_" + countPushdim;
// console.log('pushdim key', key)
    if (myList[key] !== undefined){
        myList[key].forEach(item => {
            // console.log('parsing item', item)
            if(austattung_produkt[item.product] !== undefined){
                productList.push({
                    product: austattung_produkt[item.product],
                    count: item.count
                });
                // console.log('pushed', item.product, austattung_produkt[item.product], item.count)
            } else {
                // console.log('not found', item.product, austattung_produkt)
            }
            countProducts += item.count;
        });
    }
    return countProducts;
}

function getShadingProducts(countShading, austattung_produkt, productList){

    let countForFrameShading = 0;
    let amount_for_products_2 = 0;

    if(countShading > 0){
        amount_for_products_2 = Math.floor((countShading / 2));

        productList.push({
            product: austattung_produkt["verschatt-2"],
            count: amount_for_products_2
        });
        productList.push({
            product: austattung_produkt["verschatt-2-w"],
            count: amount_for_products_2
        });
        if(countShading % 2 !== 0){
            productList.push({
                product: austattung_produkt["verschatt-1"],
                count: 1
            });
            productList.push({
                product: austattung_produkt["verschatt-1-w"],
                count: 1
            });
            countForFrameShading += 1;
        }
        countForFrameShading += amount_for_products_2;
    }
    return getShadingFrames(countForFrameShading, austattung_produkt, productList);
}

function getShadingFrames(countForFrameShading, austattung_produkt, productList){

    let amount_for_frames_2 = 0;

    amount_for_frames_2 = Math.floor((countForFrameShading / 2));

    if(amount_for_frames_2 > 0){
        productList.push({
            product: austattung_produkt["r-2f"],
            count: amount_for_frames_2
        })
    }
    if (countForFrameShading % 2 !== 0){
        productList.push({
            product: austattung_produkt["r-1f"],
            count: 1
        })
    }
    return;
}



export default function getProducts(project, austattung_produkt){

    const productList = [];

    const checkList = [
        {
            equipmentKey: "audio_anthrazit-weissrauch",
            productKeys: [
                "innenstation",
                "spannung-tuer",
                "trafo",
                "rahmen-t-audio",
                "domovea-expert-tuer"
            ],
        },
        {
            equipmentKey: "audio_anthrazit-weissrauch-2",
            productKeys: [
                "innenstation-video",
                "spannung-tuer",
                "trafo",
                "rahmen-design-video",
                "domovea-expert-tuer"
            ],
        },
        {
            equipmentKey: "tuerko-mobil",
            productKeys: [
                "video-set-w",
                "rahmen-t-video",
            ],
        },
        {
            equipmentKey: "tuerko-mobil-a",
            productKeys: [
                "video-set-a",
                "rahmen-t-video",
            ],
        }
    ];

    checkList.forEach(item => {
        if(
            project.selectedEquipment[item.equipmentKey] === undefined
            || !Boolean(project.selectedEquipment[item.equipmentKey])
        ){
            return;
        }
        item.productKeys.forEach(pk => {
            if(austattung_produkt[pk] !== undefined){
                productList.push({
                    product: austattung_produkt[pk],
                    count: 1
                });
            }
        })
    });

    return productList;
}


export default function GenerateProductList(project, selectedRoomFilter, selectedProductFilter, onlyWithProductSwitch = false){

    const list = [];
    let categories = [];
    let csvList = [];

    const roomFilterList = [];
    const reihenfolge = [
        "Tastsensoren",
        "Steckdosen",
        "Anschlussdosen",
        "Rahmen",
        "Türkommunikation",
        "Aktoren",
        "Systemgeräte",
        "Sensoren",
        "Heizungsventile",
        "Server"
    ]
    let current = {room: '', floor: ''};

    const selectedFloor = selectedRoomFilter && selectedRoomFilter.floor !== undefined && selectedRoomFilter.floor !== 'all' ? project.generatedProject.floors.filter(f => f.id === selectedRoomFilter.floor)[0] : undefined;
    const selectedRoom = selectedRoomFilter && selectedRoomFilter.room !== undefined && selectedRoomFilter.floor !== 'all' ? selectedFloor.rooms.filter(i => i.id === selectedRoomFilter.room)[0] : undefined;
    const roomFilters = [];
    const isAllRooms = selectedRoomFilter && selectedRoomFilter.floor === 'all';
// console.log('isAllRooms', isAllRooms, project.selectedAlternatives)
    if (isAllRooms) {
        project.generatedProject.floors.forEach( floor => {
            const floorTmp = {
                id: floor.id,
                name: floor.name,
                rooms: []
            }
            floor.rooms.forEach(room => {
                let roomTmp = {
                    id: room.id,
                    name: room.name,
                    products: []
                };
                let roomProducts = [];
                const productSwitches = [];
                if (project.selectedAlternatives && Object.keys(project.selectedAlternatives).length > 0) {
                    Object.keys(project.selectedAlternatives).forEach(key => {
                        if (project.selectedAlternatives[key] !== undefined) {
                            productSwitches.push(project.selectedAlternatives[key]);
                        }
                    });
                }
                room.products.forEach(p => {
                    // console.log('parsing per rooms product', p.productSwitch, productSwitches)
                    if (p.productSwitch === undefined) { roomProducts.push(p); }
                    else {
                        const found = p.productSwitch.filter(i => productSwitches.includes(i.value))[0];
                        if (found) {
                            found.products.forEach((fp, fpIdx) => {
                                if (found.counts !== undefined && found.counts[fpIdx] !== undefined) {
                                    fp = {...fp, count: found.counts[fpIdx]};
                                } else { fp = {...fp, count: p.count}; }
                                const rpFound = roomProducts.filter(k => k.Artikelnummer === fp.Artikelnummer)[0];
                                if (rpFound) {
                                    roomProducts = roomProducts.map(k => k.Artikelnummer === fp.Artikelnummer ? {...k, count: k.count + fp.count} : k);
                                } else {
                                    roomProducts.push(fp);
                                }
                            });
                        }
                    }
                });
                roomProducts.map(p => {
                    if (categories.filter(k => k === p.kategorie)[0] === undefined) {
                        categories.push(p.kategorie);
                        list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                    }
                    if(selectedProductFilter !== undefined && selectedProductFilter !== p.kategorie){ return p; }
                    list[p.kategorie].products.push({...p, roomId: room.id, floorId: floor.id, roomName: room.name, floorName: floor.name});
                    roomTmp.products.push({...p, roomId: room.id, floorId: floor.id, roomName: room.name, floorName: floor.name});
                    if (!list[p.kategorie].productKeys.includes(p.Artikelnummer)) { list[p.kategorie].productKeys.push(p.Artikelnummer); }
                    return p;
                });
                roomTmp = {
                    ...roomTmp,
                    products: roomTmp.products.sort((a, b) => reihenfolge.indexOf(a.kategorie) - reihenfolge.indexOf(b.kategorie)),
                };
                floorTmp.rooms.push(roomTmp);
            });
            roomFilterList.push(floorTmp);
        });
    }
    if (!isAllRooms) {
        // console.log('selectedalternatives', project.selectedAlternatives)
        project.generatedProject.floors.forEach(floor => {
            const floorTmp = {
                id: floor.id,
                name: floor.name,
                rooms: [],
                allRooms: []
            }
            floor.rooms.forEach(room => {
                if (
                    selectedRoomFilter !== undefined && selectedRoomFilter.floor !== 'all'
                    && selectedRoomFilter.room !== undefined
                    && selectedRoomFilter.room !== room.id
                ) {
                    if (selectedProductFilter !== undefined) {
                        const found = room.products.filter(p => p.kategorie === selectedProductFilter)[0];
                        if (found !== undefined) {
                            floorTmp.rooms.push({
                                id: room.id,
                                name: room.name
                            });
                            room.products.forEach(p => {
                                if (
                                    categories.filter(k => k === p.kategorie)[0] === undefined &&
                                    selectedRoom.products.filter(rp => rp.kategorie === p.kategorie)[0] !== undefined
                                ) {
                                    categories.push(p.kategorie);
                                    list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                                }
                            });
                        }
                        return;
                    }
                    floorTmp.rooms.push({
                        id: room.id,
                        name: room.name
                    });
                    return;
                }
                let addRoomToFilter = false;
                const roomProducts = [];
                const productSwitches = [];
                if (project.selectedAlternatives && Object.keys(project.selectedAlternatives).length > 0) {
                    Object.keys(project.selectedAlternatives).forEach(key => {
                        if (project.selectedAlternatives[key] !== undefined) {
                            productSwitches.push(project.selectedAlternatives[key]);
                        }
                    });
                }
                // console.log('productswitches', productSwitches)
                room.products.forEach(p => {
                    // console.log('parsing p', p)
                    if (p.productSwitch === undefined || onlyWithProductSwitch) { roomProducts.push(p); }
                    else {
                        const found = p.productSwitch.filter(i => productSwitches.includes(i.value))[0];
                        if (found) {
                            found.products.forEach((fp, fpIdx) => {
                                if (found.counts !== undefined && found.counts[fpIdx] !== undefined) {
                                    fp = {...fp, count: found.counts[fpIdx]};
                                } else { fp = {...fp, count: p.count}; }
                                roomProducts.push(fp);
                                // console.log('pushed switch', fp)
                            });
                        } else {
                            // console.log("SWITCH NOT FOUND", p.productSwitch, productSwitches)
                            roomProducts.push(p);
                        }
                    }
                });
                roomProducts.forEach(p => {
                    if (
                        (
                            selectedProductFilter !== undefined
                            && selectedProductFilter !== p.kategorie
                        )
                        ||
                        (
                            onlyWithProductSwitch
                            && p.productSwitch === undefined
                        )
                    ) {
                        return;
                    }
                    // console.log('parsing product', p)
                    addRoomToFilter = true;
                    if (list[p.kategorie] === undefined) {
                        categories.push(p.kategorie);
                        list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                    }
                    if (list[p.kategorie].products[p.Artikelnummer] === undefined) {
                        list[p.kategorie].productKeys.push(p.Artikelnummer);
                        list[p.kategorie].products[p.Artikelnummer] = {...p};
                    } else {
                        list[p.kategorie].products[p.Artikelnummer].count += p.count;
                    }
                })
                if (addRoomToFilter) {
                    floorTmp.rooms.push({
                        id: room.id,
                        name: room.name
                    });
                    current.room = room.name;
                    current.floor = floor.name;
                }
                if (csvList.filter(l => l.roomId === room.id)[0] === undefined) {
                    csvList.push({roomId: room.id, products: []});
                }
                room.products.forEach(p => {
                    csvList = csvList.map(r => r.roomId === room.id ? {...r, products: [...r.products, p]} : r);
                });
            });
            if (floorTmp.rooms.length > 0) {
                roomFilterList.push(floorTmp);
            }
            roomFilters.push(floorTmp.allRooms);
        });
    }

    let productList = [];
    let catFilterList = [];

    categories = categories.sort((a,b) => reihenfolge.indexOf(a.name) - reihenfolge.indexOf(b.name));
    categories.forEach( c => {
        const cat = {name: list[c].name, products: []}
        list[c].productKeys.forEach(pk => {
            if (!isAllRooms) { cat.products.push(list[c].products[pk]); }
            else {
                list[c].products.map(lp => {
                    if (pk.toString() === lp.Artikelnummer.toString()) { cat.products.push(lp); }
                    return lp;
                });
            }
        });
        productList.push(cat);
        catFilterList.push({
            id: cat.name,
            name: cat.name
        });
    });
    productList = productList.sort((a,b) => reihenfolge.indexOf(a.name) - reihenfolge.indexOf(b.name));
    catFilterList = catFilterList.sort((a,b) => reihenfolge.indexOf(a.name) - reihenfolge.indexOf(b.name));

    let roomName = 'alle';
    if (selectedRoomFilter && selectedRoomFilter.floor !== undefined && selectedRoomFilter.floor !== 'all' && selectedRoomFilter.room !== undefined) {
        roomName = selectedFloor.name + ' - ';
        roomName += selectedFloor.rooms.filter(i => i.id === selectedRoomFilter.room)[0].name;
    }
    return {
        productList: productList,
        catFilterList: catFilterList,
        roomFilterList: roomFilterList,
        csvProductList: csvList,
        isAllRooms: isAllRooms,
        room: roomName,
        product: selectedProductFilter
    };
}



export default function getProducts(room, austattung_produkt, selectedAlternatives){
    const checkList = [
        {
            key: "praesenzmelder",
            value: 'praesenzmelder'
        },
        {
            key: "bewegungsmelder_z",
            value: 'bewegungsmelder_z'
        }
    ];
    const productList = [];
    checkList.forEach(check => {
        if(
            room.selectedEquipment["melden_bewegung"] !== undefined
            && Boolean(room.selectedEquipment["melden_bewegung"])
            && austattung_produkt["praesenzmelder"] !== undefined
        ){
            if (productList.length) { return; }
            if(
                selectedAlternatives !== undefined
                && selectedAlternatives["praesenzmelder"] !== undefined
            ) {
                productList.push({
                    product: austattung_produkt['bewegungsmelder_11'],
                    count: 1,
                    alt: [
                        {
                            key: 'praesenzmelder',
                            value: undefined,
                            products: [austattung_produkt['praesenzmelder']]
                        },
                        {
                            key: 'praesenzmelder',
                            value: 'bm',
                            products: [austattung_produkt['bewegungsmelder_11'], austattung_produkt['bewegungsmelder_z']]
                        }
                    ]
                });
                productList.push({
                    product: austattung_produkt['bewegungsmelder_z'],
                    count: 1
                });
            } else {
                productList.push({
                    product: austattung_produkt['praesenzmelder'],
                    count: 1,
                    alt: [
                        {
                            key: 'praesenzmelder',
                            value: undefined,
                            products: [austattung_produkt['praesenzmelder']]
                        },
                        {
                            key: 'praesenzmelder',
                            value: 'bm',
                            products: [austattung_produkt['bewegungsmelder_11'], austattung_produkt['bewegungsmelder_z']]
                        }
                    ]
                });
            }
        }
    });
    return productList;
}

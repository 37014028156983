import React, {useEffect} from "react"
import EquimentSelectComponent from "./EquimentSelectComponent";
import gaEventTracker from "../services/gaEventTracker";
import gaPageTracker from "../services/gaPageTracker";

export default function StepFourComponent({project, equipmentData, updateProject, updateProjectWithNewLevel, updateQueryParams, isExpert, saveIdTracking}){
    useEffect(() => {
        document.title = 'Hager easyplan - Schritt 4';
        updateQueryParams('step_4', document.title);
        gaPageTracker((isExpert ? '/expert' : '/customer') + '/page_4');
    }, []);
    function setConfLevel(e, level){
        e.stopPropagation();
        updateProjectWithNewLevel({...project, selectedLevel: level});
    }

    return <div>
        <div className={"section-container"} style={{paddingTop: '0'}}>
            <div className="section-title">Weitere Ausstattungwünsche</div>
            <div className={"section-sub-title"}>Haben Sie weitere Ausstattungswünsche?</div>
            { equipmentData.map( (item, idx) => <EquimentSelectComponent project={project} equipmentData={item} updateProject={updateProject} key={item.id} idx={idx} setLevel={setConfLevel} saveIdTracking={saveIdTracking} />)}
        </div>
    </div>
}

import ProjectSelectComponent from "./ProjectSelectComponent";
import FloorComponent from "./FloorComponent";
import AddFloorComponent from "./AddFloorComponent";
import React, {useEffect} from "react";
import iconFenster from "./../images/figma/window.svg"
import iconTuer from "./../images/figma/door.svg"
import iconHeizung from "./../images/figma/heating.svg"
import iconFussbodenHeizung from "./../images/figma/heating_underfloor.svg"
import {ReactSortable} from "react-sortablejs";
import {v4 as uuidv4} from "uuid";
import {/*Tooltip,*/ withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import radioUnchecked from "../images/figma/radio_unchecked.svg"
import radioChecked from "../images/figma/radio_checked.svg"
import gaEventTracker from "../services/gaEventTracker";
import gaPageTracker from "../services/gaPageTracker";

export default function StepOneComponent({project, projectSelectList, setSelectedProject, updateProject, schalterdesign, startProject, updateQueryParams, isExpert, saveIdTracking}){

    const [dragActive, setDragActive] = React.useState(false);
    const [showAddFloor, setShowAddFloor] = React.useState(true);
    useEffect(() => {
        document.title = 'Hager easyplan - Schritt 1';
        updateQueryParams('step_1', document.title);
        gaPageTracker((isExpert ? '/expert' : '/customer') + '/page_1');
    }, []);

    function onStart(){
        setDragActive(true)
    }
    function onEnd(){
        setDragActive(false)
    }
    function showHideAddFloor(value) {
        setShowAddFloor(value);
    }
    function onAddFloor(floor){
        saveIdTracking('btn_building_structure_cta', 'btn_building_structure_add_floor');
        const newProject = {
            ...project,
            floors: [
                ...project.floors,
                {...floor, id: uuidv4()}
            ]
        };
        updateProject(newProject);
    }
    function ChangeProjectName(name){
        updateProject({...project, projectName: name});
    }
    function setFloors(floors){
        updateProject({...project, floors: floors});
    }
    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : floor)});
    }
    function deleteFloor(floor){
        updateProject({...project, floors: project.floors.filter(f => f.id !== floor.id)});
    }

    const ColorButton4 = withStyles((theme) => ({
        root: {
            color: "var(--textColor)",
            '&:hover': {
                borderColor:"var(--blue)",
            },
            cursor: "pointer",
            alignContent: "center",
            padding: "23px",
            fontSize: "16px",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "46%",
            border: "2px solid var(--borderColor)",
            margin: "0 auto",
            '&:first-child': {
                marginLeft: '0'
            },
            '&:last-child': {
                marginRight: '0'
            }
        },
    }))(Button);
    const ColorButton4active = withStyles((theme) => ({
        root: {
            color: "var(--textColor)",
            border: "2px solid var(--blue)",
            '&:hover': {
                background: "transparent"
            },
            cursor: "pointer",
            alignContent: "center",
            padding: "25px",
            fontSize: "16px",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "46%",
            margin: "0 auto",
            '&:first-child': {
                marginLeft: '0'
            },
            '&:last-child': {
                marginRight: '0'
            }
        },
    }))(Button);

    function getFloorTotal(cat) {
        let total = 0;
        project.floors.map(f => f.rooms.map(r => total += r[cat]));
        return total;
    }
    function getButton4(text, type, hoverText1, hoverText2, description){
        if(project.projectType === type) {
            return <ColorButton4active id={type === 'easy' ? 'ob_project_type_smart' : 'ob_project_type_conventional'} className={"ob_project_type"} onClick={e => startProject(type, false, true)}>
                <div className={"section-radio-option"}>
                    <img src={radioChecked} alt={"radio"} width="24" />
                    <strong>{text}</strong>
                    <p className={"description"}>{description}</p>
                </div>
            </ColorButton4active>
        }
        return <ColorButton4 id={type === 'easy' ? 'ob_project_type_smart' : 'ob_project_type_conventional'} onClick={e => startProject(type, false, true)} className={"system-select-button ob_project_type"}>
            <div className={"section-radio-option"}>
                <img src={radioUnchecked} alt={"radio"} width="24" />
                <strong>{text}</strong>
                <p className={"description"}>{description}</p>
            </div>
        </ColorButton4>
    }

    return <div>
        <div className={"section-container"}>
            <div className={"section-title"}>Neues Projekt</div>
            <input type="text" name="projectName" onChange={e => ChangeProjectName(e.target.value)} value={project.projectName} className="inputTextName" placeholder={"Projektname eingeben"}/>
        </div>
        <div className={"section-container"}>
            <div className={"section-title"}>Elektroinstallation wählen</div>
            <div className={"section-sub-title"}>Möchten Sie eine smarte oder eine konventionelle Elektroinstallation planen?</div>
            <div className={"section-container-row"}>
                {getButton4("Smart", "easy", "Smart", "", "Für den Neubau und die umfangreiche Renovierung. Mit easy lässt sich ein Smart Home / eine KNX-Anlage leicht, schnell und intuitiv konfigurieren.\nDabei ist easy eine leistungsstarke und intelligente Haussteuerung, mit der Sie alle Grundfunktionen für ein intelligentes Gebäude realisieren. Durch die Möglichkeit, den Funktionsumfang zu erweitern, wird easy auch gehobenen Ansprüchen gerecht.")}
                {getButton4("Konventionell", "konventionell", "Konventionell", "", "Die konventionelle Elektro-Planung beschränkt sich auf die einfachste Funktionalität der Elektroinstallation. Sie ermöglicht Ihnen z.B. die elektrische Steuerun von Beleuchtung, verzichtet aber auf ein höheres Maß an Komfort und Energieeffizienz, wie z.B. Heizungs- oder Sprachsteuerung.")}
            </div>
        </div>
        <div className={"section-container"}>
            <div className={"section-title"}>Gebäudetyp auswählen</div>
            <div className={"section-sub-title"}>
                <ul className="section-text">
                    <li>Wählen Sie ein zu Ihrem Projekt passendes Musterhaus aus. Anschließend können Sie die vordefinierten Ausstattungen und Funktionen mit wenigen Klicks an Ihre persönlichen Anforderungen anpassen.</li>
                    {/*<li>Geben Sie die korrekte Anzahl an Türen, Fenstern und Heizkörpern bzw. Fußbodenheizung pro Raum an.</li>*/}
                </ul>
            </div>
            <div className={"section-container-row"}>
                <ProjectSelectComponent selectedIx={project.selectedMusterIx} projects={projectSelectList} onSelectProject={setSelectedProject} />
            </div>
        </div>
        <div className={"section-container"}>
            <div className="row stickyRow">
                <div className="text-left col-4">
                    <div className={"section-title"}>Gebäudestruktur anpassen</div>
                    <div className={"section-sub-title"}>Legen Sie alle Stockwerke und Räume an und geben Sie die Anzahl an <strong>Türen, Fenstern, Heizung</strong> und <strong>Fußbodenheizung</strong> pro Raum ein</div>
                </div>
                <div className="text-center col-2">
                    <div className={"roomCounter"}>{getFloorTotal('doorCount')}</div>
                    <img src={iconTuer} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "50px"}}/>
                    <div className="svgDescription" style={{marginTop: "30px"}}>Tür</div>
                </div>
                <div className="text-center col-2">
                    <div className={"roomCounter"}>{getFloorTotal('windowCount')}</div>
                    <img src={iconFenster} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "50px"}}/>
                    <div className="svgDescription" style={{marginTop: "30px"}}>Fenster</div>
                </div>
                <div className="text-center col-2">
                    <div className={"roomCounter"}>{getFloorTotal('heatingCount')}</div>
                    <img src={iconHeizung} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "50px"}}/>
                    <div className="svgDescription" style={{marginTop: "30px"}}>Heizkörper</div>
                </div>
                <div className="text-center col-2">
                    <div className={"roomCounter"}>{getFloorTotal('floorheatingCount')}</div>
                    <img src={iconFussbodenHeizung} alt="" style={{maxWidth: "100%", maxHeight: "100%", height: "50px"}}/>
                    <div className="svgDescription" style={{marginTop: "30px"}}>Fußbodenheizung</div>
                </div>
            </div>
            <div className={"floorList step1" + (dragActive ? " dragActive" : "")}>
                <ReactSortable
                    list={project.floors}
                    setList={l => setFloors(l)}
                    group={"floorLists"}
                    handle={".dragHandle"}
                    onStart={onStart}
                    onEnd={onEnd}
                >
                    {project.floors.map((f, idx) => <FloorComponent floor={f} key={f.id} project={project} updateFloor={updateFloor} deleteFloor={deleteFloor} schalterdesign={schalterdesign} opened={idx === 0} withCounter={true} isLast={idx === project.floors.length - 1} onAddFloor={onAddFloor} onFloorClose={showHideAddFloor} saveIdTracking={saveIdTracking} />)}
                </ReactSortable>
            </div>
            {showAddFloor ? <AddFloorComponent onAddFloor={onAddFloor} rightSide={true} /> : null}
        </div>
    </div>
}



export default function getProducts(project, austattung_produkt){

    const productList = [];

    let addDomoveaBasic = false;
    const checkForDomoveaBasic = (project.selectedEquipment["tuerko-mobil"] === undefined || !Boolean(project.selectedEquipment["tuerko-mobil"]));

    project.floors.forEach(floor => {
        floor.rooms.forEach(room => {
            if(
                checkForDomoveaBasic
                && room.selectedEquipment["heizen_fussbodenheizung"] !== undefined
                && Boolean(room.selectedEquipment["heizen_fussbodenheizung"])
                && austattung_produkt["domovea-basic"] !== undefined
            ){
                addDomoveaBasic = true;
            }
        })
    })

    if(addDomoveaBasic){
        productList.push({
            product: austattung_produkt["domovea-expert-tuer"], //["domovea-basic"],
            count: 1
        });
    }

    return productList;
}

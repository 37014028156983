

export default function getProducts(project, austattung_produkt){

    const productList = [];
    if (project.selectedEquipment === undefined) { project.selectedEquipment = {}; }

    if(
        (
            project.selectedEquipment["tuerko-mobil"] === undefined
            || !Boolean(project.selectedEquipment["tuerko-mobil"])
        )
        &&
        (
            (
                project.selectedEquipment["Sonos"] !== undefined
                && Boolean(project.selectedEquipment["Sonos"])
            )
            ||
            (
                project.selectedEquipment["smartphone"] !== undefined
                && Boolean(project.selectedEquipment["smartphone"])
            )
            ||
            (
                project.selectedEquipment["sprachsteuerung"] !== undefined
                && Boolean(project.selectedEquipment["sprachsteuerung"])
            )
        )
        && project.selectedLevel < 2
    ){
        productList.push({
            product: austattung_produkt['domovea-expert-tuer'], //["domovea-basic"],
            count: 1
        })
    }

    return productList;
}



export default function getProducts(room, austattung_produkt, selectedAlternatives){

    const productList = {list:[], listPro: []};

    if(
        room.type in [
            "8142128b-51cd-47ed-8c79-7ef5b2ae83d9", //Treppenhaus
            "d8cf675b-2a77-42b9-8be7-5b9f7b6ff4f9", //Flur/Diele über 3 qm
            "46e3e159-d5a5-4623-9cd6-8f66f5fad7fa", //Kellertreppe / Flur
        ]
        && room.selectedEquipment["melden_bewegung"] !== undefined
        && Boolean(room.selectedEquipment["melden_bewegung"])
    ){
        return productList;
    }

    let countFirst = 0;
    let countAll = 0;

    const checkList = [
        {
            key: "schaltbare_beleuchtung",
            onlyFirst: false
        },
        {
            key: "dimmbare_beleuchtung",
            onlyFirst: false
        },
        {
            key: "schaltbare_steckdosen",
            onlyFirst: false
        },
        {
            key: "rolllaeden_steuern",
            onlyFirst: true
        },
        {
            key: "jalousie_steuern",
            onlyFirst: true
        }
    ];

    checkList.forEach(item => {
        if(
            room.selectedEquipment[item.key] !== undefined
            && room.selectedEquipment[item.key] > 0
        ){
            if(!item.onlyFirst) {
                countAll += room.selectedEquipment[item.key];
            }
            countFirst += room.selectedEquipment[item.key];
        }
    });

    let countForFrameFirst = 0;
    let countForFrameAll = 0;
    let countForFrameFirst_t4 = 0;
    let countForFrameAll_t4 = 0;
    const taster_list = [
        {factor: 4, key: "t4", pKeys: ["t4", "busanakoppler"]},
        {factor: 3, key: "t3", pKeys: ["t3", "busanakoppler"]},
        {factor: 2, key: "t2", pKeys: ["t2-ba-wippe", "t2-ba"], alt: {key: "t2a", pKeys: ["t2", "busanakoppler"]}},
        {factor: 1, key: "t1", pKeys: ["t1-ba-wippe", "t1-ba"], alt: {key: "t1a", pKeys: ["t1", "busanakoppler"]}}
    ];
    if(countFirst > 0){
        taster_list.forEach(taster => {
            if(
                countFirst / taster.factor >= 1
                && austattung_produkt[taster.key] !== undefined
            ){
                const c = Math.ceil(countFirst / taster.factor);
                let altList = undefined;
                if(taster.alt !== undefined){
                    altList = [
                        {
                            key: taster.key,
                            value: undefined,
                            products: taster.pKeys.map(nk => austattung_produkt[nk])
                        },
                        {
                            key: taster.key,
                            value: taster.alt.key,
                            products: taster.alt.pKeys.map(nk => austattung_produkt[nk])
                        }
                    ];
                }
                if(
                    selectedAlternatives !== undefined
                    && selectedAlternatives[taster.key] !== undefined
                ){
                    productList.list.push({
                        product: austattung_produkt[taster.alt.pKeys[0]],
                        count: c,
                        alt: altList
                    });
                    productList.list.push({
                        product: austattung_produkt[taster.alt.pKeys[1]],
                        count: c
                    });
                    productList.listPro.push({
                        product: austattung_produkt[taster.alt.pKeys[0]],
                        count: c,
                        selectedAccessories: [austattung_produkt[taster.alt.pKeys[1]]],
                    });
                }else{
                    productList.list.push({
                        product: austattung_produkt[taster.pKeys[0]],
                        count: c,
                        alt: altList
                    });
                    productList.list.push({
                        product: austattung_produkt[taster.pKeys[1]],
                        count: c
                    });
                    productList.listPro.push({
                        product: austattung_produkt[taster.pKeys[0]],
                        count: c,
                        selectedAccessories: [austattung_produkt[taster.pKeys[1]]],
                    });
                }
                if(taster.key === "t4" && (
                    room.selectedDesign.selectedSerie.id === "s-1-e" || room.selectedDesign.selectedSerie.id === "b-3-e" ||
                    room.selectedDesign.selectedSerie.id === "b-7-e" || room.selectedDesign.selectedSerie.id === "k-1-e" ||
                    room.selectedDesign.selectedSerie.id === "k-5-e")){
                    countForFrameFirst_t4 += c;
                }else {
                    countForFrameFirst += c;
                }
                countFirst -= c * taster.factor;
            }
        });
    }
    if(countAll > 0){
        taster_list.forEach(taster => {
            if(
                (room.doorCount - 1) > 0
                && countAll / taster.factor >= 1
                && austattung_produkt[taster.key] !== undefined
            ){
                const c = Math.ceil(countAll / taster.factor);
                let altList = undefined;
                if(taster.alt !== undefined){
                    altList = [
                        {
                            key: taster.key,
                            value: undefined,
                            products: taster.pKeys.map(nk => austattung_produkt[nk])
                        },
                        {
                            key: taster.key,
                            value: taster.alt.key,
                            products: taster.alt.pKeys.map(nk => austattung_produkt[nk])
                        }
                    ];
                }
                if(
                    selectedAlternatives !== undefined
                    && selectedAlternatives[taster.key] !== undefined
                ){
                    productList.list.push({
                        product: austattung_produkt[taster.alt.pKeys[0]],
                        count: c * (room.doorCount - 1),
                        alt: altList
                    });
                    productList.list.push({
                        product: austattung_produkt[taster.alt.pKeys[1]],
                        count: c * (room.doorCount - 1)
                    });
                    productList.listPro.push({
                        product: austattung_produkt[taster.alt.pKeys[0]],
                        count: c * (room.doorCount - 1),
                        selectedAccessories: [austattung_produkt[taster.alt.pKeys[1]]],
                    });
                }else{
                    productList.list.push({
                        product: austattung_produkt[taster.pKeys[0]],
                        count: c * (room.doorCount - 1),
                        alt: altList
                    });
                    productList.list.push({
                        product: austattung_produkt[taster.pKeys[1]],
                        count: c * (room.doorCount - 1)
                    });
                    productList.listPro.push({
                        product: austattung_produkt[taster.pKeys[0]],
                        count: c * (room.doorCount - 1),
                        selectedAccessories: [austattung_produkt[taster.pKeys[1]]],
                    });
                }
                if(taster.key === "t4" && (
                    room.selectedDesign.selectedSerie.id === "s-1-e" || room.selectedDesign.selectedSerie.id === "b-3-e" ||
                    room.selectedDesign.selectedSerie.id === "b-7-e" || room.selectedDesign.selectedSerie.id === "k-1-e" ||
                    room.selectedDesign.selectedSerie.id === "k-5-e")){
                    countForFrameAll_t4 += c;
                }else {
                    countForFrameAll += c;
                }
                countAll -= c * taster.factor;
            }
        });
    }
    [{count: countForFrameFirst, factor: 1}, {count: countForFrameAll, factor: (room.doorCount -1)}].forEach(countObj => {
        let innerCount = countObj.count;
        [
            {factor: 5, key: "r-5f"},
            {factor: 4, key: "r-4f"},
            {factor: 3, key: "r-3f"},
            {factor: 2, key: "r-2f"},
            {factor: 1, key: "r-1f"}
        ].forEach(frame => {
            if(
                innerCount / frame.factor >= 1
                && austattung_produkt[frame.key] !== undefined
            ){
                const c = Math.ceil(innerCount / frame.factor);
                productList.list.push({
                    product: austattung_produkt[frame.key],
                    count: c * countObj.factor
                });
                productList.listPro.push({
                    product: austattung_produkt[frame.key],
                    count: c * countObj.factor,
                    selectedAccessories: [],
                });
                innerCount -= c * frame.factor;
            }
        });
    });

    if(countForFrameFirst_t4 > 0){
        productList.list.push({
            product: austattung_produkt["s-r1f"],
            count: countForFrameFirst_t4
        })
        productList.listPro.push({
            product: austattung_produkt["s-r1f"],
            count: countForFrameFirst_t4,
            selectedAccessories: [],
        });
    }
    if(countForFrameAll_t4 > 0){
        productList.list.push({
            product: austattung_produkt["s-r1f"],
            count: countForFrameFirst_t4 * (room.doorCount -1)
        })
        productList.listPro.push({
            product: austattung_produkt["s-r1f"],
            count: countForFrameFirst_t4 * (room.doorCount -1),
            selectedAccessories: [],
        });
    }


    return productList;
}



export default function getProducts(project, austattung_produkt, selectedAlternatives){

    const productList = [];

    if(
        project.selectedEquipment["weatherstation"] !== undefined
        && Boolean(project.selectedEquipment["weatherstation"])
    ){
        if(project.projectType === 'knx'){
            const altList = [
                {
                    key: 'weather-station',
                    value: undefined,
                    products: [austattung_produkt['weather-station']]
                },
                {
                    key: 'weather-station',
                    value: 'weather-station-a',
                    products: [austattung_produkt['weather-station']]
                }
            ];

            if(
                selectedAlternatives !== undefined
                && selectedAlternatives['weather-station'] !== undefined
            ){
                productList.push({
                    product: austattung_produkt['weather-station'],
                    count: 1,
                    alt: altList
                });
            }else{
                productList.push({
                    product: austattung_produkt['weather-station'],
                    count: 1,
                    alt: altList
                });
            }
        }else{
            productList.push({
                product: austattung_produkt['weather-station'],
                count: 1,
            });
        }

    }

    return productList;
}

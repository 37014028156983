

export default function getProducts(project, austattung_produkt, selectedAlternatives){

    const productList = [];

    const checkList = [
        {
            key: "schaltbare_beleuchtung",
            factor: 1
        },
        {
            key: "schaltbare_steckdosen",
            factor: 1
        },
        {
            key: "jalousie_steuern",
            factor: 2
        }
    ];

    let countForCheck = 0;
    let countForDimmen = 0;
    let countForRollade = 0;

    project.floors.forEach(floor => {
        floor.rooms.forEach(room => {
            checkList.forEach(item => {
                if(
                    room.selectedEquipment[item.key] !== undefined
                    && room.selectedEquipment[item.key] > 0
                ){
                    countForCheck += room.selectedEquipment[item.key] * item.factor;
                }
            });
            if(
                room.selectedEquipment["dimmbare_beleuchtung"] !== undefined
                && room.selectedEquipment["dimmbare_beleuchtung"] > 0
            ){
                countForDimmen += room.selectedEquipment["dimmbare_beleuchtung"];
            }
            if(
                room.selectedEquipment["rolllaeden_steuern"] !== undefined
                && room.selectedEquipment["rolllaeden_steuern"] > 0
            ){
                countForRollade += room.selectedEquipment["rolllaeden_steuern"] * room.windowCount;// * 2; // uncommented because of mail Markus 19.07.2022 10:04
            }
        });
    })

    let newDalis = [
        {factor: 128, key: "dali-gateway-2fach"},
        {factor: 64, key: "dali-gateway-1fach"},
    ];
    // console.log('project', project, selectedAlternatives)
    if (project.isExpert && austattung_produkt["dali-gateway-2fach"] !== undefined) {
        const lastDali = newDalis[newDalis.length - 1];
        newDalis.forEach(dali => {
            // console.log('parsing dali', dali, countForDimmen, countForDimmen / dali.factor, austattung_produkt[dali.key])
            if (
                (
                    (countForDimmen / dali.factor >= 1 && countForDimmen > lastDali.factor) ||
                    (countForDimmen / dali.factor <= 1 && dali.factor === lastDali.factor && countForDimmen > 0)
                )
                && austattung_produkt[dali.key] !== undefined
            ) {
                const c = Math.floor(countForDimmen / dali.factor);
                const dimmAktors = [];
                const dimmAktorsCount = [];
                let nrDimm = c > 0 ? c * dali.factor : countForDimmen;
                [
                    {factor: 4, key: "dimm-aktor-4"},
                    {factor: 3, key: "dimm-aktor-3"},
                    {factor: 2, key: "dimm-aktor-2"},
                    {factor: 1, key: "dimm-aktor-1"}
                ].forEach(aktor => {
                    if (
                        nrDimm / aktor.factor >= 1
                        && austattung_produkt[aktor.key] !== undefined
                    ) {
                        const ca = Math.floor(nrDimm / aktor.factor);
                        dimmAktors.push(austattung_produkt[aktor.key]);
                        dimmAktorsCount.push(ca);
                        nrDimm -= ca * aktor.factor;
                    }
                });

                if (
                    selectedAlternatives !== undefined
                    && selectedAlternatives['dali-option|' + austattung_produkt[dali.key]] !== undefined
                    && selectedAlternatives['dali-option|' + austattung_produkt[dali.key]] !== 'dali-option|' + austattung_produkt[dali.key]
                ) {
                    productList.push({
                        product: 'dali-option|' + austattung_produkt[dali.key],
                        count: c > 0 ? c : 1,
                        alt: [
                            {
                                key: 'dali-option|' + austattung_produkt[dali.key],
                                value: undefined,
                                products: [austattung_produkt[dali.key]]
                            },
                            {
                                key: 'dali-option|' + austattung_produkt[dali.key],
                                value: 'dali-option|' + austattung_produkt[dali.key],
                                products: dimmAktors,
                                counts: dimmAktorsCount
                            },
                        ]
                    });
                } else {
                    productList.push({
                        product: 'dali-option|' + austattung_produkt[dali.key],
                        count: c > 0 ? c : 1,
                        alt: [
                            {
                                key: 'dali-option|' + austattung_produkt[dali.key],
                                value: undefined,
                                products: [austattung_produkt[dali.key]]
                            },
                            {
                                key: 'dali-option|' + austattung_produkt[dali.key],
                                value: 'dali-option|' + austattung_produkt[dali.key],
                                products: dimmAktors,
                                counts: dimmAktorsCount
                            },
                        ]
                    });
                }
                countForDimmen -= (c > 0 ? c : 1) * dali.factor;
            }
        });
        // console.log('productlist', productList)
    } else if (austattung_produkt["dali-aktor"] !== undefined) {
        newDalis = [{factor: 4, key: "dali-aktor"}];
        if (countForDimmen > 0) {
            [
                {factor: 4, key: "dimm-aktor-4"},
                {factor: 3, key: "dimm-aktor-3"},
                {factor: 2, key: "dimm-aktor-2"},
                {factor: 1, key: "dimm-aktor-1"}
            ].forEach(aktor => {
                if (
                    countForDimmen / aktor.factor >= 1
                    && austattung_produkt[aktor.key] !== undefined
                ) {
                    const c = Math.floor(countForDimmen / aktor.factor);
                    // productList.push({
                    //     product: austattung_produkt[aktor.key],
                    //     count: c
                    // })
                    const found = newDalis[0];
                    if (found) {
                        const daliProdukt = {
                            key: aktor.key,
                            value: found.key,
                            products: [austattung_produkt[found.key]]
                        };
                        if (
                            selectedAlternatives !== undefined
                            && selectedAlternatives[aktor.key] !== undefined
                            && selectedAlternatives[aktor.key] !== found.key
                        ) {
                            productList.push({
                                product: austattung_produkt[found.key],
                                count: c,
                                alt: [
                                    {
                                        key: aktor.key,
                                        value: undefined,
                                        products: [austattung_produkt[aktor.key]]
                                    },
                                    daliProdukt
                                ]
                            });
                        } else {
                            productList.push({
                                product: austattung_produkt[aktor.key],
                                count: c,
                                alt: [
                                    {
                                        key: aktor.key,
                                        value: undefined,
                                        products: [austattung_produkt[aktor.key]]
                                    },
                                    daliProdukt
                                ]
                            });
                        }
                    } else {
                        let dTotal = c * aktor.factor;
                        const daliProdukts = [];
                        const daliCounts = [];
                        newDalis.forEach(dali => {
                            if (dTotal / dali.factor >= 1 && austattung_produkt[dali.key] !== undefined) {
                                const cc = Math.floor(dTotal / dali.factor);
                                daliProdukts.push(austattung_produkt[dali.key]);
                                daliCounts.push(cc);
                                dTotal -= cc * dali.factor;
                            }

                        });
                        if (
                            selectedAlternatives !== undefined
                            && selectedAlternatives[aktor.key] !== undefined
                            && selectedAlternatives[aktor.key] !== 'dali-option|' + austattung_produkt[aktor.key]
                        ) {
                            productList.push({
                                product: 'dali-option|' + austattung_produkt[aktor.key],
                                count: c,
                                alt: [
                                    {
                                        key: aktor.key,
                                        value: undefined,
                                        products: [austattung_produkt[aktor.key]]
                                    },
                                    {
                                        key: aktor.key,
                                        value: 'dali-option|' + austattung_produkt[aktor.key],
                                        products: daliProdukts,
                                        counts: daliCounts
                                    }
                                ]
                            });
                        } else {
                            productList.push({
                                product: austattung_produkt[aktor.key],
                                count: c,
                                alt: [
                                    {
                                        key: aktor.key,
                                        value: undefined,
                                        products: [austattung_produkt[aktor.key]]
                                    },
                                    {
                                        key: aktor.key,
                                        value: 'dali-option|' + austattung_produkt[aktor.key],
                                        products: daliProdukts,
                                        counts: daliCounts
                                    }
                                ]
                            });
                        }
                    }
                    // if(
                    //     selectedAlternatives !== undefined
                    //     && selectedAlternatives[aktor.key] !== undefined
                    //     && selectedAlternatives[aktor.key] !== 'dali-aktor'
                    // ) {
                    //     productList.push({
                    //         product: austattung_produkt["dali-aktor"],
                    //         count: c,
                    //         alt: [{
                    //                 key: aktor.key,
                    //                 value: undefined,
                    //                 products: [austattung_produkt[aktor.key]]
                    //             },
                    //             {
                    //                 key: aktor.key,
                    //                 value: "dali-aktor",
                    //                 products: [austattung_produkt["dali-aktor"]]
                    //             }]
                    //     });
                    // } else {
                    //     productList.push({
                    //         product: austattung_produkt[aktor.key],
                    //         count: c,
                    //         alt: [{
                    //             key: aktor.key,
                    //             value: undefined,
                    //             products: [austattung_produkt[aktor.key]]
                    //         },
                    //             {
                    //                 key: aktor.key,
                    //                 value: "dali-aktor",
                    //                 products: [austattung_produkt["dali-aktor"]]
                    //             }]
                    //     });
                    // }
                    countForDimmen -= c * aktor.factor;
                }
            });
        }
    } else {
        if (countForDimmen > 0) {
            [
                {factor: 4, key: "dimm-aktor-4"},
                {factor: 3, key: "dimm-aktor-3"},
                {factor: 2, key: "dimm-aktor-2"},
                {factor: 1, key: "dimm-aktor-1"}
            ].forEach(aktor => {
                if (
                    countForDimmen / aktor.factor >= 1
                    && austattung_produkt[aktor.key] !== undefined
                ) {
                    const c = Math.floor(countForDimmen / aktor.factor);
                    productList.push({
                        product: austattung_produkt[aktor.key],
                        count: c
                    });
                    countForDimmen -= c * aktor.factor;
                }
            });
        }
    }
    if(countForRollade > 0){
        if(
            countForRollade  > 4
            && austattung_produkt["rolladen-aktor-8"] !== undefined
        ){
            const c = Math.ceil(countForRollade / 8);
            productList.push({
                product: austattung_produkt["rolladen-aktor-8"],
                count: c
            })
            countForRollade -= c * 8;
        }
        if(
            countForRollade  > 0
            && austattung_produkt["rolladen-aktor-4"] !== undefined
        ){
            productList.push({
                product: austattung_produkt["rolladen-aktor-4"],
                count: 1
            })
        }
    }
// console.log('countforcheck', countForCheck)
    if(countForCheck > 0){
        [
            {factor1: 10, factor: 20, key: "sch-aktor-20-10"},
            {factor1: 8, factor: 16, key: "sch-aktor-16-8"},
            {factor1: 8, factor: 10, key: "sch-aktor-10-5"},
            {factor1: 6, factor: 8, key: "sch-aktor-8-4"},
            {factor1: 3, factor: 6, key: "sch-aktor-6-3"},
            {factor1: 2, factor: 4, key: "sch-aktor-4-2"}
        ].forEach(aktor => {
            if(
                countForCheck >=  aktor.factor
                && austattung_produkt[aktor.key] !== undefined
            ){
                const c = Math.ceil(countForCheck / aktor.factor);
                // console.log('c', c, aktor)
                productList.push({
                    product: austattung_produkt[aktor.key],
                    count: c
                })
                countForCheck -= c * aktor.factor;
                // console.log('countforcheck 2', countForCheck)
            }
        });
        if(
            countForCheck  > 0
            && austattung_produkt["sch-aktor-4-2"] !== undefined
        ){
            productList.push({
                product: austattung_produkt["sch-aktor-4-2"],
                count: 1
            })
            // console.log('added 4-2', countForCheck)
        }
    }

    return productList;
}



export default function getProducts(project, austattung_produkt, selectedAlternatives){

    const productList = {list:[], removeKeys: [austattung_produkt['domovea-basic'], austattung_produkt['domovea-expert-tuer']]};
    const checkList = [
        {
            key: "Sonos",
            value: 'domovea-basic'
        },
        {
            key: "smartphone",
            value: 'domovea-basic'
        },
        {
            key: "sprachsteuerung",
            value: 'domovea-basic'
        },
    ];
    const roomCheckList = [
        {
            key: "heizen_fussbodenheizung",
            value: 'domovea-basic'
        },
    ];
    // const altList = [
    //     {
    //         key: 'domovea-basic',
    //         value: undefined,
    //         products: [austattung_produkt['domovea-basic']]
    //     },
    //     {
    //         key: 'domovea-basic',
    //         value: 'domovea-expert-tuer',
    //         products: [austattung_produkt['domovea-expert-tuer']]
    //     }
    // ];
    // parse rooms first for heating
    roomCheckList.forEach(check => {
        project.floors.forEach(floor => {
           floor.rooms.forEach(room => {
               if (room.selectedEquipment[check.key] !== undefined && Boolean(room.selectedEquipment[check.key])) {
                   if (productList.list.length) { return; }
                   if(
                       selectedAlternatives !== undefined
                       && selectedAlternatives["domovea-basic"] !== undefined
                   ) {
                       productList.list.push({
                           product: austattung_produkt['domovea-expert-tuer'],
                           count: 1,
                           // alt: altList
                       });
                   } else {
                       productList.list.push({
                           product: austattung_produkt['domovea-expert-tuer'], //['domovea-basic'],
                           count: 1,
                           // alt: altList
                       });
                   }
               }
           });
        });
    });
    checkList.forEach(check => {
        if(
            project.selectedEquipment[check.key] !== undefined
            && Boolean(project.selectedEquipment[check.key])
        ){
            if (check.override) {
                productList.list = [];
                productList.list.push({
                    product: austattung_produkt['domovea-expert-tuer'],
                    count: 1,
                });
            }
            if (productList.list.length) { return; }
            if(
                selectedAlternatives !== undefined
                && selectedAlternatives["domovea-basic"] !== undefined
            ) {
                productList.list.push({
                    product: austattung_produkt['domovea-expert-tuer'],
                    count: 1,
                    // alt: altList
                });
            } else {
                productList.list.push({
                    product: austattung_produkt['domovea-expert-tuer'], //['domovea-basic'],
                    count: 1,
                    // alt: altList
                });
            }
        }
    });
    return productList;
}

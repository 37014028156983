import React from 'react';
import ReactDOM from 'react-dom';
import "./index.scss"
import {BrowserRouter as Router} from "react-router-dom";
import RouterComponent from "./components/RouterComponent";

ReactDOM.render(
    <Router>
        <RouterComponent />
    </Router>,
  document.getElementById('root')
);


import React from "react";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LabelBox from "./LabelBox/LabelBox";
import CheckBox from "./CountBox/CheckBox";

export default function FloorDesignComponent({floor, updateFloor, data, generateCompositeImage, currentDesign, uiState, schalterdesigns, isFirst = false}){

    const [open, setOpen] = React.useState(true);
    const [roomHistorie, setRoomHistorie] = React.useState({});
    const firstColWidth = '400px';

    function onOpenClose(){
        setOpen(!open);
    }
    function updateRoom(room, idx = -1){
        setRoomHistorie(
            {
                ...roomHistorie,
                [room.id]: room.selectedDesign
            }
        );
        if (idx < 0) {
            updateFloor({
                ...floor,
                rooms: floor.rooms.map(r => r.id !== room.id ? r : {...room, selectedDesign: currentDesign})
            });
        } else {
            const design = uiState.selectedDesignList[idx];
            const newDesign = {
                selectedColor1: schalterdesigns[design.selectedSerie].colorsTaster[design.selectedColorTaster],
                selectedColor2: schalterdesigns[design.selectedSerie].colorsTaster[design.selectedColorTaster].colorsRahmen[design.selectedColorRahmen],
                selectedSerie: schalterdesigns[design.selectedSerie]
            };
            updateFloor({
                ...floor,
                rooms: [...floor.rooms.map(r => {
                    if (r.id !== room.id) { return r; }
                    else { return {...r, selectedDesign: newDesign}; }
                })]
            });
        }
    }

    function generateRoomList(){
        return floor.rooms.map(r => generateRoom(r));
    }


    function generateRoom(room){

        return <div className="roomRow row" key={room.id} style={{lineHeight:"40px", verticalAlign:"middle"}}>
            <div className="col-4 contextBoxHover" style={{flexDirection: 'row', minWidth: firstColWidth, maxWidth: firstColWidth}}>
                <div className="openClose" style={{width: '0'}}> </div>
                <LabelBox value={room.name} editAble={false} isBold={false} style={{display: "inline-flex"}}/>
            </div>
            <GenerateSelectedDesigns value={room} />
        </div>;
    }
    function setRoomDesign(room, newValue, idx) {
        if (newValue === 'on') { updateRoom(room, idx); }
    }
    function setFloorDesign(room, newValue, idx) {
        if (newValue === 'on') {
            const design = uiState.selectedDesignList[idx];
            const newDesign = {
                selectedColor1: schalterdesigns[design.selectedSerie].colorsTaster[design.selectedColorTaster],
                selectedColor2: schalterdesigns[design.selectedSerie].colorsTaster[design.selectedColorTaster].colorsRahmen[design.selectedColorRahmen],
                selectedSerie: schalterdesigns[design.selectedSerie]
            };
            updateFloor({
                ...floor,
                rooms: [...floor.rooms.map(r => {
                    return {...r, selectedDesign: newDesign};
                })]
            });
        }
    }
    function RoomColumnChecker(val){
        const room = val.value.room;
        const idx = val.value.idx;
        if (!room) { return <></>; }
        const serie = schalterdesigns[uiState.selectedDesignList[idx].selectedSerie];
        const color1 = serie.colorsTaster[uiState.selectedDesignList[idx].selectedColorTaster];
        const color2 = color1.colorsRahmen[uiState.selectedDesignList[idx].selectedColorRahmen];
        const checValue = color1.colorId === room.selectedDesign.selectedColor1.colorId
                && color2.colorId === room.selectedDesign.selectedColor2.colorId
                && serie.id === room.selectedDesign.selectedSerie.id;
        return <><CheckBox value={checValue} setValue={setRoomDesign} room={room} idx={idx} /></>;
    }
    function RoomColumnHeaderChecker(val){
        const idx = val.value;
        const serie = schalterdesigns[uiState.selectedDesignList[idx].selectedSerie];
        const color1 = serie.colorsTaster[uiState.selectedDesignList[idx].selectedColorTaster];
        const color2 = color1.colorsRahmen[uiState.selectedDesignList[idx].selectedColorRahmen];
        let found = false;
        floor.rooms.forEach(room => {
            const checValue = color1.colorId !== room.selectedDesign.selectedColor1.colorId
                || color2.colorId !== room.selectedDesign.selectedColor2.colorId
                || serie.id !== room.selectedDesign.selectedSerie.id;
            found = found || checValue;
        });
        return <><CheckBox value={!found} setValue={setFloorDesign} isHeader={true} idx={idx} /></>;
    }
    function GenerateSelectedDesigns(room) {
        return <>
            {uiState.selectedDesignList[0] !== undefined
                ? <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}><RoomColumnChecker value={{room: room.value, idx: 0}}/></div>
                : <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}></div>}
            {uiState.selectedDesignList[1] !== undefined
                ? <div className={"text-center col-1"} style={{padding: "5px"}}><RoomColumnChecker value={{room: room.value, idx: 1}}/></div>
                : <div className={"text-center col-1"} style={{padding: "5px"}}></div>}
            {uiState.selectedDesignList[2] !== undefined
                ? <div className={"text-center col-1"} style={{padding: "5px"}}><RoomColumnChecker value={{room: room.value, idx: 2}}/></div>
                : <div className={"text-center col-1"} style={{padding: "5px"}}></div>}
        </>;
    }

    return <div className="floorWrapper" key={data.id + "xx" + floor.id} style={{marginBottom: "70px", marginTop: isFirst ? "15px" : "20px"}}>
        <div className={"row floorBoxHeader"}>
            <div className="col-4" style={{minWidth: firstColWidth, maxWidth: firstColWidth}}>
                <div className="openClose orange" onClick={onOpenClose}>
                    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                </div>
                <LabelBox value={floor.name} editAble={false} isBold={true}/>
            </div>
            {uiState.selectedDesignList[0] !== undefined
                ? <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}><RoomColumnHeaderChecker value={0}/></div>
                : <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}></div>}
            {uiState.selectedDesignList[1] !== undefined
                ? <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}><RoomColumnHeaderChecker value={1}/></div>
                : <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}></div>}
            {uiState.selectedDesignList[2] !== undefined
                ? <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}><RoomColumnHeaderChecker value={2}/></div>
                : <div className={"text-center col-1 vcenter"} style={{padding: "5px"}}></div>}
        </div>
        {open ? generateRoomList() : null }
    </div>
}

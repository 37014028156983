import React from "react";
import ReactGA from "react-ga4";

const gaEventTracker = (category= "some category") => {
    const eventTracker = (action = "test action", label = "text label") => {
        // console.log('tracking event', category, action, label);
        // ReactGA.event({category, action, label});
    }
    return eventTracker;
};
export default gaEventTracker;

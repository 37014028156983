import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {ListItemIcon, ListItemText} from "@material-ui/core";
import {GenerateImagePath} from "../services/GenerateImagePath";

export default function ProductSelectSwitchComponent({list, project, updateProject}){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [marginLeft, setMarginLeft] = React.useState(0);

    const handleClick = (event) => {
        setMarginLeft(ReactDOM.findDOMNode(event.currentTarget).clientWidth);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectProducts = (key, value) => {
        const list = (project.selectedAlternatives !== undefined) ? project.selectedAlternatives : {};
        list[key] = value;
        updateProject({...project, selectedAlternatives: list});
        handleClose();
    }

    return <>
        <div className="button-product-switch" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <FlipCameraAndroidIcon style={{verticalAlign: "bottom", width: "0.7em", height: "0.7em", color: "var(--color3)"}}/>&nbsp;
            <span style={{color: "var(--color3)"}}>Produkt Auswahl</span>
        </div>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{marginLeft: (0.8 * marginLeft) + "px"}}
        >
            {list.map((item, idx) => <MenuItem key={item.key + "_" + idx} style={{fontSize: "inherit", alignItems: "start"}} className="selectedAlternativeProductListItem" onClick={event => onSelectProducts(item.key, item.value)}>
                <ListItemIcon style={{fontSize: "inherit", minWidth: "30px", marginTop: "8px"}}>
                    {((project.selectedAlternatives === undefined && item.value === undefined) || (project.selectedAlternatives !== undefined && project.selectedAlternatives[item.key] === item.value)) ? <RadioButtonCheckedIcon style={{width: "o.6em", height: "o.7em"}}/> : <RadioButtonUncheckedIcon />}
                </ListItemIcon>
                <ListItemText style={{fontSize: "inherit"}}>
                {
                    item.products.map(product =>
                        <div style={{flex: "0 0 100%", fontSize: "inherit", lineHeight: "35px"}} key={product.Artikelnummer}>
                            <img src={GenerateImagePath.getPath(product.Artikelnummer)} alt="" style={{maxWidth: "30px", display: "inline", verticalAlign: "middle", paddingRight: "5px"}}/>
                            {product.Bezeichnung} (UVP: {
                            <NumberFormat value={(product.Preis / 100)}
                                      displayType={'text'} suffix={" €"}
                                      thousandSeparator={"."}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      decimalSeparator={","}/>
                    })</div>)
                }
                </ListItemText>
                </MenuItem>)}
        </Menu>
    </>
}

import knx_austattung_produkt from "../data/knx_austattung_produkt.json";
import easy_austattung_produkt from "../data/easy_austattung_produkt.json";
import konnventionell_austattung_produkt from "../data/konventionell_austattung_produkt.json";


export default function GetAusstattungProduktObj(project, room){
    let t = [];
    switch(project.projectType){
        case "knx":
            t = knx_austattung_produkt.filter(p => (
                p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                && p.wippe.toString() ===room.selectedDesign.selectedColor1.colorId.toString()
                && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
            ));
            break;
        case "easy":
            t = easy_austattung_produkt.filter(p => (
                p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                && p.wippe.toString() ===room.selectedDesign.selectedColor1.colorId.toString()
                && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
            ));
            break;
        case "konventionell":
            t = konnventionell_austattung_produkt.filter(p => (
                p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                && p.wippe.toString() ===room.selectedDesign.selectedColor1.colorId.toString()
                && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
            ));
            break;
        default:
            t = [];
    }

    if(t.length > 0){
        return t[0];
    }
    return t;
}

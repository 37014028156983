
export class GenerateImagePath{

    static getPath(id){

        var src = "https://www.projekt14.de/austausch/quick_h/";
        src += id;
        src += ".png?t=" + new Date().getTime();
        return   src;
    }
}

import React, {useEffect} from "react"
import SelectDesignComponent from "./SelectDesignComponent";
import knx_shalterdesign from "../data/knx_schalterdesign.json";
import easy_shalterdesign from "../data/easy_schalterdesign.json";
import konventionell_shalterdesign from "../data/konventionell_schalterdesign.json";
import gaEventTracker from "../services/gaEventTracker";
import gaPageTracker from "../services/gaPageTracker";

export default function StepThreeComponent({project, updateProject, uiState, setUiState, updateQueryParams, isExpert, saveIdTracking}){
    useEffect(() => {
        document.title = 'Hager easyplan - Schritt 3';
        updateQueryParams('step_3', document.title);
        gaPageTracker((isExpert ? '/expert' : '/customer') + '/page_3');
    }, []);

    function getSchalterDesign() {
        switch (project.projectType) {
            case "knx":
                return knx_shalterdesign;
            case "easy":
                return easy_shalterdesign;
            case "konventionell":
                return konventionell_shalterdesign;
            default:
                return [];
        }
    }

    return <div>
        <div className="fs-box-white-30-white no-margin-top no-border" style={{margin: '0', padding: '0'}}>
            <SelectDesignComponent
                schalterdesigns={getSchalterDesign()}
                project={project}
                updateProject={updateProject}
                uiState={uiState}
                updateUiState={setUiState}
                saveIdTracking={saveIdTracking}
            />
        </div>
    </div>
}

import React from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import addIcon from "../images/figma/add_floor.svg";

export default function AddFloorComponent({onAddFloor, rightSide = false}){

    const floorList = [
        {name: "Kellergeschoss", type: "934224a6-8d3a-4004-a0be-85c0b4d13cec", rooms: []},
        {name: "Erdgeschoss", type: "b84dbf25-11f8-46f7-9849-0c40d120c5b4", rooms: []},
        {name: "Obergeschoss/Etage", type: "a54e5e51-9d7d-4d4c-a787-ef9502433343", rooms: []},
        {name: "Dachgeschoss", type: "21adeed2-57fc-458b-939e-24ffe96347c7", rooms: []},
    ];

    function onAddFloorInner(floor){
        onAddFloor(floor);
        handleClose();
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className={!rightSide ? "addFloorWrapper" : "addFloorWrapper right"}>
        <div className={"header" + (!Boolean(anchorEl) ? " flex" : "")}>
            <div id={"btn_building_structure_add_floor"} className="button btn_building_structure_cta" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
                 style={{border: '1px solid var(--color1)', padding: '12px', display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '0'}}
            >
                <img alt={""} src={addIcon} height="14" />
                <span style={{color: "var(--color1)", fontSize: '14px'}}>Etage hinzufügen</span>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{marginLeft: "-115px"}}
            >
                {floorList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addFloorFloorListItem" onClick={event => onAddFloorInner(r)}>{r.name}</MenuItem>)}
            </Menu>
        </div>
    </div>
}

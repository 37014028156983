
import GetAusstattungProduktObj from "../AusstattungProduktService";
import getRauchmelder from "./AddRauchmerlder"
import getBewegungsmelder from "./AddBewegungsmelder"
import getSteckdosen from "./AddSteckdosen"
import getSteckdosenAufputz from "./AddSteckdosenAufputz"
import getSteckdosenUsb from "./AddSteckdosenUsb"
import getSteckdosenRahmen from "./AddSteckdosenRahmen"
import getTelefonDatenanschluss from "./AddTelefonDatenanschluss"
import getTelefonanschluss from "./AddTelefonanschluss"
import getRadioDatenSat from "./AddRadioDatenSat"
import getRadioDatenKabel from "./AddRadioDatenKabel"
import getAnschlussdosenRahmen from "./AddAnschlussdosenRahmen"
import getTuerkommunikation from "./AddTuerkommunikation"
import getHeizungRaum from "./AddHeizungRaum"
import getTaster from "./AddTaster"
import getAktoren from "../knx_ausstattung/AddAktoren";
import getServer from "../knx_ausstattung/AddServer";

export default function GenerateProjectService(projectIn, productList){

    const generatedProject = {
        code: null,
        name: projectIn.name,
        selectedAlternatives: (projectIn.selectedAlternatives !== undefined) ? projectIn.selectedAlternatives : {},
        floors: []
    };
    let building = {
        name: projectIn.name,
        type: projectIn.type,
        floors: []
    };
    let systemProductList = [];
    let systemProductListPro = [];
    let addSpannung = false;
    projectIn.floors.forEach( floorIn => {
        const floor = {
            id: floorIn.id,
            name: floorIn.name,
            type: floorIn.type,
            rooms: []
        };
        const floorPro = {
            name: floorIn.name,
            type: floorIn.type,
            rooms: []
        }
        floorIn.rooms.forEach(roomIn => {
            const room = {
                id: roomIn.id,
                name: roomIn.name,
                type: roomIn.type,
                products: []
            }
            const roomPro = {
                name: roomIn.name,
                type: roomIn.type,
                devices: []
            }
            const austattung_produkt = GetAusstattungProduktObj(projectIn, roomIn);
            let productListInner = [];
            let productListInnerPro = [];

            //todo use algorithm to fill productListInner and systemProductList
            productListInner = productListInner.concat(getRauchmelder(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getBewegungsmelder(roomIn, austattung_produkt, projectIn.selectedAlternatives));
            productListInner = productListInner.concat(getSteckdosen(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosenAufputz(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosenUsb(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosenRahmen(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getTelefonDatenanschluss(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getTelefonanschluss(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getRadioDatenSat(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getRadioDatenKabel(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getAnschlussdosenRahmen(roomIn, austattung_produkt));
            const taster = getTaster(roomIn, austattung_produkt, projectIn.selectedAlternatives);
// console.log('getTaster konventionell', taster)
            if(taster.length > 0){
                productListInner = productListInner.concat(taster);
            }
            const heizung = getHeizungRaum(roomIn, austattung_produkt, projectIn.selectedAlternatives);
            if(heizung.length > 0){
                systemProductList = systemProductList.concat(heizung);
            }

            productListInner.forEach( p => {
                productListInnerPro.push({
                    product: p.product.toString(),
                    count: p.count,
                    selectedAccessories: [],
                });
                const productTmp = room.products.find(pi => pi.Artikelnummer.toString() === p.product.toString());
                if(productTmp === undefined){
                    const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                    if(pp === undefined){
                        return;
                    }
                    const productToPush = {...pp, count: p.count};
                    if(p.alt !== undefined){
                        const nlist = p.alt.map(pa => {
                            const altList = [];
                            pa.products.forEach(paPro => {
                                const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                                if(pp === undefined){
                                    return;
                                }
                                altList.push(ppa);
                            })
                            pa.products = altList;
                            return pa;
                        });
                        productToPush.productSwitch = nlist;
                    }
                    room.products.push(productToPush);
                }else {
                    productTmp.count += p.count;
                }
            });
            productListInnerPro.forEach( p => {
                const pp2 = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                if(pp2 === undefined){
                    return;
                }
                let selectedAccessoriesList = [];
                p.selectedAccessories.forEach( ap => {
                    const pp3 = productList.find(pl => pl.Artikelnummer.toString() === ap.toString());
                    if(pp3 !== undefined){
                        selectedAccessoriesList.push(pp3["Gtin"]);
                    }
                });
                const productToPush = {gtin: pp2["Gtin"], selectedAccessories: selectedAccessoriesList};
                for (let i = 0; i < p.count; i++) {
                    roomPro.devices.push(productToPush);
                }
            });
            floor.rooms.push(room);
            floorPro.rooms.push(roomPro);
        });
        generatedProject.floors.push(floor);
        building.floors.push(floorPro);
    });
    const austattung_produkt = GetAusstattungProduktObj(projectIn, projectIn.floors[0].rooms[0]);
    //todo use algorithm to fill systemProductList
    systemProductList = systemProductList.concat(getTuerkommunikation(projectIn, austattung_produkt));
    const akt = getAktoren(projectIn, austattung_produkt);
    if(akt.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.concat(akt);
    }
    const serverFull = getServer(projectIn, austattung_produkt, projectIn.selectedAlternatives);
    if(serverFull.list.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.filter(i => !serverFull.removeKeys.includes(i.product));
        systemProductList = systemProductList.concat(serverFull.list);
    }
    if(addSpannung){
        systemProductList.push(
            {
                product: austattung_produkt["spannung"],
                count: 1
            }
        );
    }
    systemProductList = systemProductList.filter(i => i.product !== undefined);
    systemProductListPro = [];
    if(systemProductList.length > 0){
        const uv = {
            id: "Unterverteilung", //uuidv4(), todo
            name: "Unterverteilung",
            type: "d0aea507-04d1-4cd2-95b1-7c3f94-a0d53a6c",
            products: []
        }
        const uvPro = {
            name: "Unterverteilung",
            type: "d0aea507-04d1-4cd2-95b1-7c3f94-a0d53a6c",
            devices: []
        }
        systemProductList.forEach( p => {
            systemProductListPro.push({
                product:  p.product,
                count:  p.count,
                selectedAccessories: [],
            });
            const productTmp = uv.products.find(pi => pi.Artikelnummer.toString() === p.product.toString());
            if(productTmp === undefined){
                const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                if(pp === undefined){
                    return;
                }
                const productToPush = {...pp, count: p.count};
                if(p.alt !== undefined){
                    const nlist = p.alt.map(pa => {
                        const altList = [];
                        pa.products.forEach(paPro => {
                            const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                            if(pp === undefined){
                                return;
                            }
                            altList.push(ppa);
                        })
                        pa.products = altList;
                        return pa;
                    });
                    productToPush.productSwitch = nlist;
                }
                uv.products.push(productToPush);
            }else {
                productTmp.count += p.count;
            }
        });
        systemProductListPro.forEach( p => {
            const pp2 = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString().replace('dali-option|',''));
            if(pp2 === undefined){
                return;
            }
            let selectedAccessoriesList = [];
            p.selectedAccessories.forEach( ap => {
                const pp3 = productList.find(pl => pl.Artikelnummer.toString() === ap.toString().replace('dali-option|',''));
                if(pp3 !== undefined){
                    selectedAccessoriesList.push(pp3["Gtin"]);
                }
            });
            const productToPush = {gtin: pp2["Gtin"], selectedAccessories: selectedAccessoriesList};
            for (let i = 0; i < p.count; i++) {
                uvPro.devices.push(productToPush);
            }
        });
        generatedProject.floors[0].rooms.push(uv);
        building.floors[0].rooms.push(uvPro);
    }
    let projectTypes = {
        knx: "KNX",
        easy: "HAGER_KNX_EASY",
        konventionell: "CONVENTIONAL"
    };

    const projectPro = {
        name: projectIn.projectName,
        type: projectTypes[projectIn.projectType],
        buildings: [building]
    };
    return {...projectIn, generatedProject: generatedProject, projectPro: projectPro}

}

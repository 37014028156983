import React from "react";
import ReactDOM from "react-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import roomList from "./../data/availableRooms.json";
import AddIcon from "@material-ui/icons/Add";

export default function AddRoomComponent({onAddRoom}){

    function onAddRoomInner(room){
        onAddRoom(room);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [marginLeft, setMarginLeft] = React.useState(0);

    const handleClick = (event) => {
        setMarginLeft(ReactDOM.findDOMNode(event.currentTarget).clientWidth);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <div className="addRoomWrapper">
        <div className="header">
            <div id={"btn_building_structure_add_room"} className="button btn_building_structure_cta" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <AddIcon style={{fontSize: "16px", padding: "2px", color: "var(--color1)"}}/>
                <span style={{color: "var(--color1)", fontSize: '14px'}}>Raum hinzufügen</span>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px"}}
            >
                {roomList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addRoomRoomListItem" onClick={event => onAddRoomInner(r)}>{r.name}</MenuItem>)}
            </Menu>
        </div>
    </div>
}

import React from "react";
import IconWohnung from "../images/figma/small_flat.svg";
import {ReactComponent as IconDefault} from "./../images/IconDefault.svg";
import {ReactComponent as IconEinfamilienhaus} from "./../images/IconEinfamilienhaus.svg";
import IconSmallHouse from "../images/figma/small_house.svg";
import IconBigHouse from "../images/figma/big_house.svg";
import {ReactComponent as IconStadtvillaGarageGarten} from "./../images/IconStadtvillaGarageGarten.svg";
import IconWohnungGroß from "../images/figma/big_flat.svg";
import radioChecked from "../images/figma/radio_checked.svg";
import radioUnchecked from "../images/figma/radio_unchecked.svg"

export default function ProjectSelectComponent({selectedIx, projects, onSelectProject}){

    function getIcon (icon){
        switch (icon) {
            case 'IconWohnung':
                return <img src={IconWohnung} width="100%" alt=""/>
            case 'IconEinfamilienhaus':
                return <IconEinfamilienhaus  style={{maxWidth: "100%", maxHeight: "100%"}}/>
            case 'IconEinfamilienhausGarage':
                return <img src={IconSmallHouse} width="100%" alt=""/>
            case 'IconEinfamilienhausGarageGarten':
                return <img src={IconBigHouse} width="100%" alt=""/>
            case 'IconStadtvillaGarageGarten':
                return <IconStadtvillaGarageGarten  style={{maxWidth: "100%", maxHeight: "100%"}}/>
            case 'IconWohnungGroß':
                return <img src={IconWohnungGroß} width="100%" alt=""/>
            default:
                return <IconDefault />
        }
    }


    function onSelectProjectType(project, ix){
        onSelectProject({...project, selectedMusterIx: ix});
    }
    function getBtnId(r) {
        let res = '';
        switch (r.typeId) {
            case 1: res = 'ob_building_type_small_house'; break;
            case 2: res = 'ob_building_type_large_house'; break;
            case 3: res = 'ob_building_type_small_flat'; break;
            case 4: res = 'ob_building_type_large_flat'; break;
            default: break;
        }
        return res;
    }

    return <div className="addProjectList">
        {projects.map((r, ix) =>
            <div id={getBtnId(r)} key={ix} className={"ob_building_type addProjectListItem" + (ix === selectedIx ? " active" : "")}  onClick={event => onSelectProjectType(r, ix)}>
                <img src={ix === selectedIx ? radioChecked : radioUnchecked} alt={"radio"} width="24" style={{marginBottom: "15px"}} />
                <div>{r.name}</div>
                {getIcon(r.icon)}
            </div>)
        }
    </div>
}

import React, {useEffect} from "react"
import SelectConfLevelComponent from "./SelectConfLevelComponent";
import EquimentSelectComponent from "./EquimentSelectComponent";
import gaEventTracker from "../services/gaEventTracker";
import gaPageTracker from "../services/gaPageTracker";

export default function StepTwoComponent({project, equipmentData, updateProject , updateProjectWithNewLevel, updateQueryParams, isExpert, saveIdTracking}){
    useEffect(() => {
        document.title = 'Hager easyplan - Schritt 2';
        updateQueryParams('step_2', document.title);
        gaPageTracker((isExpert ? '/expert' : '/customer') + '/page_2');
    }, []);

    function setConfLevel(e, level){
        switch (level) {
            case 1: saveIdTracking('ob_features', 'ob_features_minimal'); break;
            case 2: saveIdTracking('ob_features', 'ob_features_standart'); break;
            case 3: saveIdTracking('ob_features', 'ob_features_comfort'); break;
            default: break;
        }
        e.stopPropagation();
        updateProjectWithNewLevel({...project, selectedLevel: level});
    }

    return <div>
        <div className={"section-container"} style={{paddingTop: '0'}}>
            <div className={"section-title"}>Ausstattungswünsche angeben</div>
            <div className={"section-sub-title"}>Anhand Ihrer Wunsch-Ausstattung findet eine Vorauswahl von Komponenten statt. Diese können Sie in den folgenden Schritten anpassen.</div>
            <SelectConfLevelComponent selectedLevel={project.selectedLevel} setLevel={setConfLevel}/>
        </div>
        { equipmentData.map( (item, idx) => <EquimentSelectComponent project={project} equipmentData={item} updateProject={updateProject} key={item.id} opened={idx <= 0} setLevel={setConfLevel} saveIdTracking={saveIdTracking}/>)}
    </div>
}

import React from "react";
import "./CountBox.scss";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function CountBox({value, setValue, minValue, maxValue}){

    const [edit, setEdit] = React.useState(false);

    function onKeyPress(e){
        if(e.charCode === 13){
            setEdit(false);
        }
    }

    function onChange(e){
        updateValue(e.target.value);
    }

    function updateValue(newValue){
        if(newValue < minValue){
            newValue = minValue;
        }
        if(newValue > maxValue){
            newValue = maxValue;
        }
        setValue(newValue);
    }

    function getLabelOrInput(){
        if(edit){
            return <input type="number" max={maxValue} min={minValue} name="inputCount"
                          onChange={onChange}
                          onBlur={e => setEdit(false)}
                          value={value}
                          className="inputLabel"
                          autoFocus={true}
                          onKeyPress={onKeyPress}
            />
        }else{
            return <>
                <RemoveIcon className="CountBoxButton" onClick={handleDecrementClick}/>
                <div className="CountBoxValue" onDoubleClick={e => setEdit(true)}>{value === 0 ? '-' : value}</div>
                <AddIcon className="CountBoxButton" onClick={handleIncrementClick}/>
                </>
        }
    }

    function handleIncrementClick(){
        updateValue(value + 1);
    }
    function handleDecrementClick(){
        updateValue(value - 1);
    }

    return <div className="CountBoxWrapper">
        {getLabelOrInput()}
    </div>;
}

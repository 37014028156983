import React from "react";
import "./CheckBox.scss";
import checkMart from "../../images/figma/check_mark_white.svg"

export default function CheckBox({value, setValue, room, isHeader = false, idx = -1}){

    function onChange(e){
        updateValue(e.target.value);
    }

    function updateValue(newValue){
        setValue(room, newValue, idx);
    }

    function getCheckBox() {
        return <>
            <label className={"checkbox" + (value ? " checked" : "") + (isHeader ? " header" : "")}>
                <input type={"checkbox"} checked={value} onChange={onChange} />
                {value ? <img src={checkMart} alt={""} /> : null}
            </label>
        </>;
    }

    return <div className="CheckBoxWrapper">
        {getCheckBox()}
    </div>;
}

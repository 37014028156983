

export default function getProducts(project, austattung_produkt){

    const productList = [];
    const checkList = [
        {
            equipmentKey: "audio_anthrazit-weissrauch",
            productKeys: [
                "innenstation",
                "spannung-tuer",
                "trafo",
                "rahmen-t-audio",
                "domovea-expert-tuer"
            ],
        },
        {
            equipmentKey: "audio_anthrazit-weissrauch-2",
            productKeys: [
                "innenstation-video",
                "spannung-tuer",
                "trafo",
                "rahmen-design-video",
                "domovea-expert-tuer"
            ],
        },
        {
            equipmentKey: "video_anthrazit-weiss",
            productKeys: [
                "video-set-a",
                "rahmen-t-video",
            ],
        },
        {
            equipmentKey: "video_weiss-weiss",
            productKeys: [
                "video-set-w",
                "rahmen-t-video",
            ],
        },
        {
            equipmentKey: "tuerko-mobil",
            productKeys: [
                "video-set-w",
                "rahmen-t-video",
            ],
        },
        {
            equipmentKey: "tuerko-mobil-a",
            productKeys: [
                "video-set-a",
                "rahmen-t-video",
            ],
        }
    ];
    const removeList = [];
    checkList.forEach(item => {
        if(
            project.selectedEquipment[item.equipmentKey] === undefined
            || !Boolean(project.selectedEquipment[item.equipmentKey])
        ){
            return;
        }
        item.productKeys.forEach(pk => {
            if(austattung_produkt[pk] !== undefined){
                productList.push({
                    product: austattung_produkt[pk],
                    count: 1
                });

            }
        })
        if (item.equipmentKey.indexOf('tuerko') >= 0 || item.equipmentKey.indexOf('audio_anthrazit-weissrauch') >= 0) {
            removeList.push(austattung_produkt["domovea-expert-tuer"]);
            removeList.push(austattung_produkt["domovea-basic"]);
        }
    });

    return {list: productList, removeList: removeList};
}
